import {START_LOADING_FAQ,FINISH_LOADING_FAQ} from "../actions/home"
const initState = {
    isLoading: false,
    faqs: [
        // "查询成绩的流程是什么？",
        // "办理在校证明需要什么材料？",
        // "可以在宿舍使用大功率电器吗？",
        // "寝室有没有空调？",
        // "国家奖学金什么时候评审？",
        // "怎么查看剩余电费？"
    ]
}

export default function reducer(state = initState,action) {
    switch (action.type) {
        case START_LOADING_FAQ:
            return {
                ...state,
                isLoading: true
            }
        case FINISH_LOADING_FAQ:
            return {
                ...state,
                faqs:action.faqs ?? [],
                isLoading:false
            }
        default:
            return state
    }
}