import React, { Component } from 'react';
import propTypes from 'prop-types';

import Navgation from './navgation';
import Finally from './finally';

import styles from '../common.module.less';

class NavigationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fold: false,
    };
    this.unfoldPanel = this.unfoldPanel.bind(this);
    this.foldPanel = this.foldPanel.bind(this);
    this.renderbackUp = this.renderbackUp.bind(this);
  }

  unfoldPanel() {
    this.setState({
      fold: false,
    });
  }

  foldPanel() {
    this.setState({
      fold: true,
    });
  }

  renderbackUp() {
    let b = null;
    if (this.props.backUp) {
      if (this.props.navigations.length > 3) {
        if (!this.state.fold) {
          b = <Finally backupStr={this.props.backUp} />;
        }
      } else {
        b = <Finally backupStr={this.props.backUp} />;
      }
    }
    return b;
  }

  UNSAFE_componentWillMount() {
    if (this.props.navigations.length > 3) {
      this.setState({
        fold: true,
      });
    }
  }

  render() {
    return (
      <div>
        <div>
          {this.state.fold ? (
            <div>
              <Navgation
                key={`${this.props.dialogKey}n${0}`}
                navigation={this.props.navigations[0]}
              />
              <Navgation
                key={`${this.props.dialogKey}n${1}`}
                navigation={this.props.navigations[1]}
              />
              <Navgation
                key={`${this.props.dialogKey}n${2}`}
                navigation={this.props.navigations[2]}
              />
            </div>
          ) : (
            this.props.navigations.map((n, i) => {
              return <Navgation key={`${this.props.dialogKey}n${i}`} navigation={n} />;
            })
          )}
          {this.renderbackUp()}
        </div>
        {this.state.fold ? (
          <div onClick={this.unfoldPanel} style={{ textAlign: 'center', padding: 2 }}>
            <span className={styles.foldOrNotButton}>查看更多</span>
            {/* 查看更多&nbsp;&nbsp;
                <svg width="10" height="6" xmlns="http://www.w3.org/2000/svg" style={{alignSelf: "center"}}><path d="M1 1l3.965 4 1.843-1.827L9 1" stroke="#3C6BC0" fill="none" fillRule="evenodd"/></svg> */}
          </div>
        ) : null}
        {!this.state.fold && this.props.navigations.length > 3 ? (
          <div onClick={this.foldPanel} style={{ textAlign: 'center', padding: 2 }}>
            <span className={styles.foldOrNotButton}>收起部分</span>
            {/* 收起&nbsp;&nbsp;
                <svg width="10" height="6" xmlns="http://www.w3.org/2000/svg" style={{alignSelf: "center"}}><path d="M1 5l3.965-4L9 5" stroke="#3C6BC0" fill="none" fillRule="evenodd"/></svg> */}
          </div>
        ) : null}
      </div>
    );
  }
}

NavigationList.propTypes = {
  navigations: propTypes.array,
  dialogKey: propTypes.string,
  backUp: propTypes.string,
};

export default NavigationList;
