import React, { Component } from 'react';
import { Icon } from 'antd';
import propTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import { sendMessage } from '../../redux/actions/home';
import { ReactComponent as AskSvg } from '../../images/icon/ask.svg';
import styles from './index.module.less';

class FaqItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.props.sendMessage({ render: this.props.content, send: this.props.content });
  }
  render() {
    return (
      <div className={styles.faqItem} onClick={this.handleClick}>
        <div style={{ display: 'flex', color: '#000', alignItems: 'center', cursor: 'pointer' }}>
          <AskSvg/>
          &nbsp;
          <span style={{flex:1}}>{this.props.content}</span>
        </div>
        <Icon type="right" style={{ color: 'rgb(200, 200, 200)', fontSize: 12 }} />
      </div>
    );
  }
}

FaqItem.propTypes = {
  content: propTypes.string.isRequired,
  sendMessage: propTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (msg) => {
      dispatch(sendMessage(msg));
    },
  };
};

export default connect(null, mapDispatchToProps)(FaqItem);
