import React, { Component } from 'react';
import { Drawer } from 'antd';
import propTypes from 'prop-types';
import cs from 'classnames';
import ReactMde from "react-mde"
import * as Showdown from "showdown"
import "react-mde/lib/styles/css/react-mde-all.css"
import { connect } from 'react-redux';
import { playVideo ,changeMode,setNoticeVideos,setGuideStep} from '../../redux/actions/home';
import { ReactComponent as VoiceEndedSvg } from '../../images/icon/voice_ended.svg';
import { ReactComponent as SunSvg } from '../../images/icon/sun.svg';
import { ReactComponent as MoonSvg } from '../../images/icon/moon.svg';
import styles from '../dialogSection/index.module.less';
import commonStyles from '../common.module.less'
import lottie from 'lottie-web';
import soundJson from '../../lottieJson/sound.json';
import utils from '../../utils/utils'
import {Modal,Avatar} from 'antd';


const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
})

class LeftBubble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fold: false,
      checkMore: true,
      selectedTab: 'preview',
    };
  }

  resizeObserver = null
  bubbleDivRef = React.createRef(null)
  contentDivRef = React.createRef(null)
  iconRef = React.createRef(null)
  iconDivRef = React.createRef(null)
  componentDidMount() {
    if (utils.getStorage('userinfo')) {
      try{
        let userinfo = JSON.parse(utils.getStorage('userinfo'))
        if(this.props.managementOnMobileRoles?.includes(userinfo?.maxRole)){
          this.setState({
            managementOnMobileAvailable:true
          })
        }
      }catch(e){
        console.log(e)
      }
    }
    this.setState({
      trigerGuide:this.props.trigerGuide
    })
    if(this.props.contentProps.messageType === 'welcome'){
      if (!utils.getStorage('userinfo')) {
        window.location.href = window.location.origin
      }else{
        this.setState({
          userName:JSON.parse(utils.getStorage('userinfo')).name,
        })
      }
      this.setState({
        timeText:this.props.contentProps.answerObject.split('好，')[0],
        helloText:this.props.contentProps.answerObject.split('好，')[1]
      })
    }
    if(this.props.videoProps?.chatId===this.props.video?.chatId && this.props.video?.play){
      this.setState({
        play:true
      })
    }
    this.resizeObserver = new ResizeObserver(entries => {
      let height = entries[0].contentRect.height
      if (height > 200) {
        if (this.props.overflowFold) {
          this.setState({
            fold: true,
          });
        }
      }

    });
    this.resizeObserver.observe(this.contentDivRef.current)
    setTimeout(()=>{
      if(this.props.guideStep === -1 && this.props.trigerGuide){
        this.props.setGuideStep(this.props.quickAsks?.length === 0 ? 2 : 1)
      }
    },300)
  }
  componentWillUnmount(){
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
  componentDidUpdate(prevProps,prevState){
    if((prevProps.video?.videoUrl !== this.props.video?.videoUrl) || 
    (prevProps.video?.play !== this.props.video?.play) || 
    (prevProps.video?.chatId !== this.props.video?.chatId)){
      if(this.props.video?.play && (this.props.video?.chatId === this.props.videoProps?.chatId)&&(this.props.videoProps?.videoUrl || this.props.videoProps?.videoType==='qa')){
        this.setState({
          play:true
        },()=>{
          let targetObj = document.getElementsByClassName(this.iconRef.current.className)
          if(targetObj){
            for(let i=0 ; i<targetObj.length;i++){
              lottie.loadAnimation({
                container: targetObj[i],
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: soundJson,
              })
            }
          }
        })
      }else{
        this.setState({
          play:false
        })
      }
    }
    if(this.props.scrollData !== prevProps.scrollData 
      && this.props.videoProps?.videoType === 'initNotice'
      && this.props.videoProps?.videoUrl
      ){
      let compareHeight = !utils.getStorage('mode') || utils.getStorage('mode')==='video' ? document.body.clientWidth* 0.5 : 0
      setTimeout(()=>{ 
        if(this.bubbleDivRef.current.getBoundingClientRect().y - compareHeight < this.props.scrollData.parentClientHeight - 30){
          this.props.setNoticeVideos(this.props.videoProps)
        }
      },200)
    }
    if(this.props.guideStep === 3 && prevProps.guideStep === 2 && this.state.trigerGuide){
      if(this.iconDivRef.current){
        this.setState({
          modalX:this.iconDivRef.current.getBoundingClientRect().x + 2,
          modalY:this.iconDivRef.current.getBoundingClientRect().y -135
        })
      }
    }
  }
  checkMore=(e)=> {
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      drawerVisible:true,
    },()=>{
      let params = {...this.props.video}
      if(params.play ){
        if( params.chatId !== this.props.videoProps?.chatId){
          params.play = false
          this.props.playVideo(params)
        }
        else{
          setTimeout(()=>{
            let targetObj = document.getElementById(`myDrawer-${this.props.contentProps.chatId}`)?.getElementsByClassName(this.iconRef.current.className)
            if(targetObj){
              for(let i=0 ; i<targetObj.length;i++){
                lottie.loadAnimation({
                  container: targetObj[i],
                  renderer: 'svg',
                  loop: true,
                  autoplay: true,
                  animationData: soundJson,
                })
              }
            }
          },300)
        }
      }
    })
  }
  checkLess=()=> {
    this.setState({
      checkMore: true,
    });
  }

  videoAction=(e)=>{
    e.nativeEvent.stopImmediatePropagation()
    if(this.props.mode === 'text'){
      this.props.changeMode('video')
    }
    let params = {...this.props.videoProps}
    params.play = !this.state.play
    this.props.playVideo(params)
  }
  renderHead = () => {
    switch (this.props.contentProps.messageType){
      case "welcome":
        return (
          <span>
            {this.props.contentProps.answerObject.includes('晚上好') ? <MoonSvg/> : <SunSvg/>}
            &nbsp;&nbsp;<span className={styles.welcomeTitle}>{this.state.timeText}好，{this.state.userName}</span>
          </span>
        );
      case "feedback":
        return (
          <div className={styles.feedbackTitle}>
            {this.props.contentProps.time}<br/>
            {this.props.contentProps.text}
          </div>
        );
      case "notice":
        return (
          <div className={styles.noticeTitle}>
            {this.props.contentProps.question}
          </div>
        );
      case "answer":
        return this.renderContent();
      default:
        return <></>
    }
  }
  renderHeadInDrawer = () => {
    switch (this.props.contentProps.messageType){
      case "feedback":
        return (
          <div className={styles.feedbackTitle}>
            {this.props.contentProps.time}<br/>
            {this.props.contentProps.text}
          </div>
        );
      case "notice": case "answer":
        return (
          <div className={styles.noticeTitle}>
            {this.props.contentProps.question}
          </div>
        );
      default:
        return <></>
    }
  }

  renderBackground = ()=>{
    switch (this.props.contentProps.messageType){
      case "feedback": case "notice":
        return "linear-gradient(180deg, #EBF3FE 0%, #FFFFFF 42.38%)";
      default:
        return "#fff"
    }
  }
  renderBackgroundInDrawer = ()=>{
    switch (this.props.contentProps.messageType){
      case "feedback": case "notice": case "answer" :
        return "linear-gradient(180deg, #EBF3FE 0%, #FFFFFF 42.38%)";
      default:
        return "#fff"
    }
  }
  renderContent = () =>{
    return (
      <div
      className={styles.overflowBox}
      style={{
        maxHeight: this.state.fold && this.state.checkMore ? 200 : '',
        marginBottom: this.state.fold && this.state.checkMore ? -15 : 0,
        borderTop:this.props.contentProps.messageType === "notice" ? '1px solid #f0f0f0' : '',
        paddingTop:this.props.contentProps.messageType==='answer' ? 0 : 8
      }}
      ref={this.contentDivRef}
    >
      <ReactMde
        value={this.props.contentProps.messageType==='welcome' ? this.props.contentProps.answerObject.split('好，')[1] : this.props.content.props.dangerouslySetInnerHTML.__html}
        selectedTab={this.state.selectedTab}
        generateMarkdownPreview={markdown =>
        Promise.resolve(converter.makeHtml(markdown))
        }
      />
    </div>

    )
  }

  renderBubble =()=>{
    return (
      <div
      className={cs(
        styles.bubble,
        styles.leftBubble,
        this.state.fold || (this.props.contentProps.messageType === 'answer' && (this.props.contentProps.detail ?? '').length>0) ? styles.foldBubble : styles.unfoldBubble,
      )}
      style={{
        background:this.renderBackground()
      }}
      ref = {this.bubbleDivRef}
    >
      <div className={styles.titleDiv}
        style={{
          paddingBottom:this.props.contentProps.messageType==='notice' || this.props.contentProps.messageType==='feedback' ? 8 : 0
        }}
      >
          <div className={styles.flex1Div}>
            {this.renderHead()}
          </div>
          {(this.props.videoProps?.videoUrl || this.props.videoProps?.videoType==='qa')&&(
            <>
            <div ref={this.iconDivRef} className={this.props.guideStep=== 3 && this.state.trigerGuide ? styles.maskPlayIconDiv : styles.playIconDiv} onClick={this.videoAction}>
              {
                this.state.play ? 
                <div ref={this.iconRef} className={`${this.props.dialogKey}`}></div>
                : 
                <VoiceEndedSvg/>
              }
            </div>
            <Modal
              visible={this.props.guideStep=== 3 && this.state.trigerGuide}
              closable={false}
              maskClosable={false}
              onCancel={()=>this.props.setGuideStep(0)}
              cancelText={this.props.quickAsks?.length === 0 ? '跳过（2/3）' : '跳过（3/4）'}
              onOk={()=>this.props.setGuideStep(4)}
              okText='明白了'
              width={'90vw'}
              wrapClassName={commonStyles.myGuideModal}
              okButtonProps ={{type:''}}
              cancelButtonProps = {{type:'link'}}
              style={{top:this.state.modalY ?? 0}}      
            >
              <div style={{display:'flex',alignItems:'center'}}>
                <div style={{width:40}}>
                  <Avatar size={40} src={require('../../images/video.png')}/>
                </div>
                &nbsp;&nbsp;<p>如果想让辅导员停止或开始播报此条消息，可以点击这里</p>
              </div>
              <div style={{position:'absolute',left:`calc(${this.state.modalX}px - 5vw)`,bottom:-9,borderTop:'10px solid #5281ff' ,borderRight:'10px solid transparent',borderLeft:'10px solid transparent'}}></div>               
            </Modal>
            </>
          )}

      </div>
      {
        this.props.contentProps.messageType !== "answer" && this.renderContent()
      }
      {(this.state.fold && this.state.checkMore) || (this.props.contentProps.messageType === 'answer' && (this.props.contentProps.detail ?? '').length>0) ? (
        <div className={styles.loadMore} onClick={this.checkMore}>
          <span className={styles.foldOrNotButton}>查看更多</span>
        </div>
      ) : null}
      {this.state.fold && !this.state.checkMore ? (
        <div className={styles.loadLess} onClick={this.checkLess}>
          <span className={styles.foldOrNotButton}>收起部分</span>
        </div>
      ) : null}
    </div>

    )
  }
  render() {
    return (
      <div>
        {this.renderBubble()}
        <Drawer
          id={`myDrawer-${this.props.contentProps.chatId}`}
          className={styles.roundContentDrawer}
          placement={'bottom'}
          visible={this.state.drawerVisible}
          headerStyle={{
            border: 'none',
            padding: '0px 24px 12px 24px',
          }}
          bodyStyle={{ padding: 0}}
          height={''}
          onClose={()=>this.setState({drawerVisible:false})}
          destroyOnClose={true}
          zIndex={996}
        >
          <div
            className={cs(
              styles.bubble,
              styles.leftBubble,
              this.state.fold ? styles.foldBubble : styles.unfoldBubble,
            )}
            style={{
              background:this.renderBackgroundInDrawer(),
              maxHeight:'calc(' + ((window.visualViewport?.height ?? window.innerHeight) - (this.state.managementOnMobileAvailable ? 30 : 0)) + 'px - 50vw)'
            }}
          >
            <div className={styles.titleDiv}
              style={{
                paddingBottom:this.props.contentProps.messageType==='notice' || this.props.contentProps.messageType==='feedback' || this.props.contentProps.messageType==='answer' ? 8 : 0
              }}
            >
              <div className={styles.flex1Div}>
                {this.renderHeadInDrawer()}
              </div>
              {(this.props.videoProps?.videoUrl || this.props.videoProps?.videoType==='qa')&&(
                <>
                <div ref={this.iconDivRef} className={this.props.guideStep=== 3 && this.state.trigerGuide ? styles.maskPlayIconDiv : styles.playIconDiv} onClick={this.videoAction}>
                  {
                    this.state.play ? 
                    <div ref={this.iconRef} className={`${this.props.dialogKey}`}></div>
                    : 
                    <VoiceEndedSvg/>
                  }
                </div>
                </>
              )}
            </div>
            {this.renderContent()}
            {this.props.contentProps.detail && (
              <div className={styles.overflowBox} style={{marginTop:5}}>
                <div className={styles.detailTitle}>详细信息</div>
                <ReactMde
                  value={this.props.detail.props.dangerouslySetInnerHTML.__html}
                  selectedTab={this.state.selectedTab}
                  generateMarkdownPreview={markdown =>
                  Promise.resolve(converter.makeHtml(markdown))
                  }
                />    
              </div>        
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

LeftBubble.propTypes = {
  playVideo: propTypes.func,
  content: propTypes.any,
  overflowFold: propTypes.bool,
};
const mapStateToProps = (state) => {
  return {
    video: {...state.Video.video},
    mode : state.Video.mode,
    guideStep: state.Dialogs.guideStep,
    managementOnMobileRoles: state.Power.managementOnMobileRoles,
    quickAsks: state.QuickAsks.quickAsks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeMode: (params) => {
      dispatch(changeMode(params))
    },    
    playVideo: (params) => {
      dispatch(playVideo(params));
    },
    setNoticeVideos:(params)=>{
      dispatch(setNoticeVideos(params))
    },
    setGuideStep:(param)=> {
      dispatch(setGuideStep(param))
    }
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(LeftBubble);
