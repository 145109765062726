
export default{
    // localstorage读取数据
    setStorage(name,data){
        let dataType = typeof data;
        if (dataType === 'object'){
            window.localStorage.setItem(name,JSON.stringify(data));
        }
        else if(['number','string','boolean'].indexOf(dataType)>=0){
            window.localStorage.setItem(name,data);
        }
        else{
            alert("该类型不能用于本地存储");
        }
    },

    // localStorage取数据
    getStorage(name){
        let data = window.localStorage.getItem(name)
        if(data){
            let dataType = typeof data;
            if (dataType === 'object'){
                return JSON.parse(data);
            }
            else{
                return data
            }
        }else{
            return '';
        }
    },

    // localStorage移除数据
    removeStorage(name){
        window.localStorage.removeItem(name);
    },
    getCookie(key){
        if(key === '') return;
        const cookieArr = document.cookie.split(';');
        const obj = {};
        for(let i = 0; i < cookieArr.length;i++){
            if(!cookieArr[i].includes('=')){
                continue;
            }
            let cookies = cookieArr[i].split("=");
            obj[`${cookies[0].trim()}`] = cookies[1].trim();
        }
        return obj[key];
    },
    setCookie(obj){
        if(!Object.prototype.toString.call(obj).includes('Object')) return;
        let val = '';
        Object.keys(obj).forEach((item)=>{
            val+= `${item}=${obj[item]};`;
        })
        document.cookie = val;
    },
    isMobile(){
        if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    },
    isIOS(){
        if(window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
          return true; // IOS
        }else{
          return false; 
        }
    }
}