import React, { Component } from 'react';
import propTypes from 'prop-types';
import cs from 'classnames';

import styles from './common.module.less';

class DialogMe extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={cs(styles.bubbleItem, styles.clearfix, styles.bubbleItemRight)}>
        {/* <div className="botAvatar">
                <img src={require('../images/head.jpg')} alt="robot avatar" />
            </div> */}
        <div
          className={cs(styles.bubble, styles.rightBubble)}
          dangerouslySetInnerHTML={{ __html: this.props.content }}
        />
      </div>
    );
  }
}

DialogMe.propTypes = {
  content: propTypes.string,
};

export default DialogMe;
