import React, { Component } from 'react';
import propTypes from 'prop-types';
import cs from 'classnames';

import AnswerTable from '../answerTable/answerTable';
import formatAnswerHtml from '../formatAnswerHtml';
import DialogPanel from '../dialogPanel/dialogPanel';
import FaqPanel from '../faqPanel/faqPanel';
import MarkAnswer from './markAnswer';
import LeftBubble from './leftBubble';
import FirstStepTree from './firstStepTree';
import NavigationList from './navigationList';

import commonStyles from '../common.module.less';

class DialogOther extends Component {
  constructor(props) {
    super(props);
    this.formatNavigations = this.formatNavigations.bind(this);
  }
  formatNavigations(content) {
    // 气泡下方的板面，更多问题和faq
    let navigation = null;
    if (content.navigation) {
      if (content.navigation.length > 0) {
        navigation = (
          <DialogPanel>
            <div className={commonStyles.panelTitle} style={{ marginBottom: 10 }}>
              你是否想问以下问题:
            </div>
            <NavigationList
              navigations={content.navigation}
              dialogKey={this.props.dialogKey}
              backUp={content.backup_answer}
            />
          </DialogPanel>
        );
      }
    }
    return navigation;
  }

  render() {
    // 气泡里的文字
    let context = <div></div>;
    let detail = <div></div>

    switch (this.props.content.componentCode) {
      case '1':
        context = (
          <p
            dangerouslySetInnerHTML={{ __html: formatAnswerHtml(this.props.content.answerObject) }}
          ></p>
        );
        detail = (
          <p
            dangerouslySetInnerHTML={{ __html: formatAnswerHtml(this.props.content?.detail ?? '') }}
          ></p>
        );
        return (
          <div>
            <div className={cs(commonStyles.bubbleItem, commonStyles.bubbleItemLeft)}>
              {/* <div className="botAvatar">
                            <img src={require('../../images/bothead.png')} alt="robot avatar" />
                        </div> */}
              <LeftBubble trigerGuide={this.props.content.trigerGuide} contentProps={this.props.content} detail={detail} content={context} overflowFold={true} videoProps={this.props.content.videoProps} scrollData={this.props.scrollData} dialogKey={this.props.dialogKey}/>
              {this.props.content.mark ? <MarkAnswer trigerGuide={this.props.content.trigerGuide} markParams={this.props.content.mark} /> : null}
            </div>
            {this.formatNavigations(this.props.content)}
          </div>
        );
      case '0':
        context = (
          // this.props.content.answerObject
          <p
            dangerouslySetInnerHTML={{ __html: formatAnswerHtml(this.props.content.answerObject) }}
          ></p>
        );
        return (
          <div>
            <div className={cs(commonStyles.bubbleItem, commonStyles.bubbleItemLeft)}>
              {/* <div className="botAvatar">
                            <img src={require('../../images/bothead.png')} alt="robot avatar" />
                        </div> */}
              <LeftBubble content={context} contentProps={this.props.content}/> 
            </div>
            <FaqPanel />
          </div>
        );
      case '2':
        context = (
          <AnswerTable
            dialogIndex={this.props.dialogKey}
            tableArr={this.props.content.answerObject}
          />
        );
        return (
          <div>
            <div className={cs(commonStyles.bubbleItem, commonStyles.bubbleItemLeft)}>
              {/* <div className="botAvatar">
                            <img src={require('../../images/bothead.png')} alt="robot avatar" />
                        </div> */}
              <LeftBubble content={<p>以下是问题的答案</p>} overflowFold={false} />
            </div>
            <DialogPanel>{context}</DialogPanel>
            {this.props.content.mark ? <MarkAnswer markParams={this.props.content.mark} /> : null}
            {this.formatNavigations(this.props.content)}
          </div>
        );
      case '8':
        context = (
          // this.props.content.answerObject.description
          <p
            dangerouslySetInnerHTML={{
              __html: formatAnswerHtml(this.props.content.answerObject.description),
            }}
          ></p>
        );
        return (
          <div>
            <div className={cs(commonStyles.bubbleItem, commonStyles.bubbleItemLeft)}>
              {/* <div className="botAvatar">
                            <img src={require('../../images/bothead.png')} alt="robot avatar" />
                        </div> */}
              <LeftBubble content={context} overflowFold={true} />
              <MarkAnswer markParams={this.props.content.mark} />
            </div>
            {this.formatNavigations(this.props.content)}
          </div>
        );
      case '9':
        context = <FirstStepTree treeNodes={this.props.content} />;
        return (
          <div>
            <div className={cs(commonStyles.bubbleItem, styles.bubbleItemLeft)}>
              {/* <div className="botAvatar">
                                <img src={require('../../images/bothead.png')} alt="robot avatar" />
                            </div> */}
              <LeftBubble content={context} overflowFold={false} />
            </div>
            {this.formatNavigations(this.props.content)}
          </div>
        );
      default:
        return context;
    }
  }
}

DialogOther.propTypes = {
  avatar: propTypes.string,
  content: propTypes.oneOfType([propTypes.object, propTypes.string]),
  dialogKey: propTypes.string,
};

export default DialogOther;
