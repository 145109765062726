import { Component } from 'react';
import { Icon,message} from 'antd';

import utils from '../../utils/utils';
import styles from './index.module.less';
import { ReactComponent as NewMsgSvg } from '../../images/icon/new-message.svg';

class ManagementEntrance extends Component {
  constructor(props) {
    super(props);
    this.state = {
        newMessage:false
    }
  }
  componentDidMount(){
    this.onGetMessageSummarize()
    // setInterval(()=>{
    //     this.onGetMessageSummarize()
    // },5000)
  }
  onGetMessageSummarize=()=> {
    let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/shadow/clusterSummarize`
    fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'AUTHORIZATION':utils.getCookie('token')
      },
    })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else if(response.status === 401){
        this.setState({
          newMessage:false
        },()=>{
          message.error('401错误，登录失败')
        })
      } else if(response.status === 403){
        this.setState({
          newMessage:false
        },()=>{
          message.error('无访问权限')
        })
      }
    })
    .then(res => {
      if (res.resultCode === 0 && res.data?.undoneSize) {
        this.setState({
            newMessage:true
        })
      }else if(res.displayMessage){
        this.setState({
          newMessage:false
        },()=>{
          message.error(res.displayMessage)
        })
      }
    })
    .catch(e => {
        this.setState({
            newMessage:false
        })  
        window.console.log("Oops, error", e)
    })

  }
  onEnterManagement = () => {
    if(utils.isIOS()){
      window.location.href = `${process.env.REACT_APP_MANAGEMENT_WEBSITE_URL}#/student-message-on-mobile?token=${utils.getCookie('token')}` 
    }else{
      // window.open(`http://localhost:3000/#/student-message-on-mobile?token=${utils.getCookie('token')}`)
      window.open(`${process.env.REACT_APP_MANAGEMENT_WEBSITE_URL}#/student-message-on-mobile?token=${utils.getCookie('token')}`)    
    }
  }
  render() {
    return (
      <>
        <div className={styles.entranceButton} onClick={this.onEnterManagement}>
                <span style={{marginRight:5}}>
                    待处理留言
                </span>
                {this.state.newMessage && <NewMsgSvg style={{marginRight:5}}/>}
                <Icon type="right-circle"/>
        </div>
      </>
    );
  }
}

export default ManagementEntrance;
