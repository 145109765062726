import { Component } from 'react';
import 'isomorphic-fetch';

import DialogSection from '../../components/dialogSection/dialogSection';
import QuickAsk from '../../components/quickAsk/quickAsk';
import SendBox from '../../components/sendBox/sendBox';
import DrawerPanel from '../../components/drawerPanel/drawerPanel';

class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <DialogSection />
        <QuickAsk />
        <SendBox />
        <DrawerPanel />
      </>
    );
  }
}

export default Home;
