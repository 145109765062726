import { HashRouter as Router, Route } from 'react-router-dom';

import Home from '../pages/home/index';
import Cas from '../pages/cas/index';
import ManualLogin from '../pages/manualLogin/index';
import Error from '../pages/error/index'

const App = () => (
  <Router>
    <div>
      <Route exact path="/" component={Cas}></Route>
      <Route exact path="/manual-login" component={ManualLogin}></Route>
      <Route exact path="/index" component={Home}></Route>
    </div>
  </Router>
);

export default App;
