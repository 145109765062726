import React, { Component } from 'react';
import { Tag, Input, Button, Icon, Drawer } from 'antd';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import { mark, closeDrawer } from '../../redux/actions/home';
import AnswerTree from '../answerTree/answerTree';
import Message from 'antd/lib/message';
import { ReactComponent as PeopleSvg } from '../../images/icon/feedback-people.svg';
import styles from './index.module.less';

const tagsData = ['没有回答','答非所问','答案有错误', '答案没有用', '内容复杂看不懂', '字太多不想看', '缺少详细信息', '功能建议'];

class DrawerPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: [],
      value: '',
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }
  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    // console.log('You are interested in: ', nextSelectedTags);
    this.setState({ selectedTags: nextSelectedTags });
  }

  handleClose() {
    this.setState({
      selectedTags: [],
      value: '',
      mouseDown: false,
    });
    this.props.closeDrawer();
  }
  onChange(e) {
    // console.log(e.target.value)
    this.setState({
      value: e.target.value,
    });
    // if(e.target.value.length>100){
    //     Message.open({
    //         content: <p className="messageText">最多仅可输入100个字符哦</p>,
    //         icon: null
    //     })
    // }
  }
  submit() {
    // console.log(
    //   this.state.value.replace(/\r\n/g, ''),
    //   this.state.value.replace(/\n/g, ''),
    //   this.state.value.replace(/\s/g, ''),
    // );
    if (
      this.state.selectedTags.length > 0 ||
      (this.state.value &&
        this.state.value.replace(/\r\n/g, '') &&
        this.state.value.replace(/\n/g, '') &&
        this.state.value.replace(/\s/g, ''))
    ) {
      let param = { ...this.props.content };
      let tags = this.state.selectedTags.join('#');
      let comment = this.state.value;
      delete param.type;
      param.gradeComment = comment;
      param.tags = tags
      // console.log(param)
      this.props.mark(param);
      this.handleClose();
      Message.open({
        content: <p>希望下次可以让您满意</p>,
        duration:1.5,
        icon: null
      })
    }
  }
  render() {
    const selfIntro = (
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <div
        // style={{display:'flex',overflow:'row-reverse wrap'}}
        >
          {tagsData.map((tag) => (
            <Tag.CheckableTag
              checked={this.state.selectedTags.indexOf(tag) > -1}
              onChange={(checked) => this.handleChange(tag, checked)}
              style={{
                color: this.state.selectedTags.includes(tag) ? '#769cff' : '#babcc1',
                backgroundColor: this.state.selectedTags.includes(tag) ? '#d8e8ff' : '#f0f2f5',
                marginRight:10,
                padding: 8,
                fontSize: 13,
                fontWeight: 600,
                marginBottom: 15          
              }}
            >
              {tag}
            </Tag.CheckableTag>
          ))}
        </div>
        <Input.TextArea
          value={this.state.value}
          onChange={this.onChange}
          placeholder="可以详细描述一下吗..."
          autoSize={{ minRows: 3, maxRows: 5 }}
          style={{
            width: '100%',
            marginBottom: 15,
            borderRadius: 4,
            border: '2px solid #f0f0f0',
            padding: '5px 5px 25px 5px',
          }}
          maxLength={100}
        />
        <div style={{ position: 'absolute', bottom: 90, right: 25, color: 'rgb(186, 188, 193)' }}>
          {this.state.value.length}/100
        </div>
        {this.state.value.length > 100 && (
          <div style={{ position: 'absolute', bottom: 90, left: 25, color: '#eeb31b' }}>
            最多仅可输入100个字符哦
          </div>
        )}
        <button
          type="primary"
          style={{
            backgroundColor:
              this.state.selectedTags.length === 0 &&
              (!this.state.value ||
                !this.state.value.replace(/\r\n/g, '') ||
                !this.state.value.replace(/\n/g, '') ||
                !this.state.value.replace(/\s/g, ''))
                ? '#d4d7e0'
                : this.state.mouseDown
                ? '#3054dc'
                : '#4372ff',
            color: '#fff',
            padding: 10,
            border: 'none',
            borderRadius: 4,
          }}
          onClick={this.submit}
          onMouseDown={() => this.setState({ mouseDown: true })}
          onMouseMove={() => this.setState({ mouseDown: false })}
          onMouseUp={() => this.setState({ mouseDown: false })}
          onTouchStart={() => this.setState({ mouseDown: true })}
          onTouchMove={() => this.setState({ mouseDown: false })}
          onTouchEnd={() => this.setState({ mouseDown: false })}
        >
          提交
        </button>
      </div>
    );

    let { open, content } = this.props;
    let contentDom = <div className={styles.drawerContent}></div>;
    if (content.type === 'intro') {
      contentDom = selfIntro;
    } else if (content.type === 'tree') {
      // eslint-disable-next-line no-unused-vars
      contentDom = (
        <div className={styles.drawerContent}>
          <h1>请选择您的情况</h1>
          <AnswerTree />
        </div>
      );
    }
    let title = (
      <div style={{ position: 'relative', height: 70 }}>
        {/* <img
          src={require('../../images/video.png')}
          alt="welcome"
          style={{ width: 70, position: 'absolute' }}
        /> */}
        <PeopleSvg style={{ width: 70, position: 'absolute' }}/>
        <div style={{ padding: '30px 5px 0px 80px' }}>可以告诉我哪里做得不好吗</div>
      </div>
    );
    return (
      <>
        {content.type === 'tree' && (
          <Drawer
            placement={'bottom'}
            visible={open}
            closable={false}
            onClose={this.handleClose}
            height={window.innerHeight + 'px'}
            destroyOnClose={true}
          >
            {/* {contentDom} */}
            <div className={styles.drawerContent}>
              <h1>请选择您的情况</h1>
              <AnswerTree />
            </div>

            <div className={styles.drawerFooter}>
              <div onClick={this.handleClose} className={styles.closeDrawerBtn}>
                <Icon type="close" style={{ verticalAlign: 'middle' }} />
                &nbsp;关闭
              </div>
            </div>
          </Drawer>
        )}
        {content.type === 'intro' && (
          <Drawer
            className={styles.roundDrawer}
            placement={'bottom'}
            visible={open}
            headerStyle={{
              border: 'none',
              padding: '0px 24px 12px 24px',
            }}
            bodyStyle={{ padding: '10px 15px 30px 15px' }}
            height={''}
            title={title}
            onClose={this.handleClose}
            destroyOnClose={true}
          >
            {selfIntro}
          </Drawer>
        )}
      </>
    );
  }
}

DrawerPanel.propTypes = {
  mark: propTypes.func,
  isLoading: propTypes.bool,
  open: propTypes.bool,
  content: propTypes.object,
  closeDrawer: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.Drawer.isLoading,
    open: state.Drawer.open,
    content: state.Drawer.content,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mark: (msg) => {
      dispatch(mark(msg));
    },
    closeDrawer: () => {
      dispatch(closeDrawer());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerPanel);
