import { nanoid } from 'nanoid';

function getUuid() {
  let kewei_uuid = window.localStorage.getItem('kewei_uuid');

  if (!kewei_uuid) {
    const uuid = nanoid().replaceAll('-', '_');
    window.localStorage.setItem('kewei_uuid', uuid);

    return uuid;
  }

  return kewei_uuid;
}

export default getUuid;
