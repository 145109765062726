import React, { Component } from 'react';
import { Icon,Modal ,Avatar} from 'antd';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import QuickAskItem from '../quickAskItem';
import { sendMessage, openDrawer ,setGuideStep} from '../../redux/actions/home';
import styles from './index.module.less';
import commonStyles from '../common.module.less'

class QuickAsk extends Component {
  constructor(props) {
    super(props);
    this.openSelfIntroDrawer = this.openSelfIntroDrawer.bind(this);
  }

  openSelfIntroDrawer() {
    this.props.openDrawer({ type: 'intro' });
  }

  render() {
    const { isLoading, quickAskList } = this.props;
    if (quickAskList?.length === 0) {
      return (
        <></>
        // <div className={styles.quickAskDiv}>
        //   <Icon type="loading" />
        // </div>
      );
    } else {
      return (<>
        <div className={this.props.guideStep=== 1 ? styles.maskQuickAskDiv : styles.quickAskDiv}>
          {/* <div onClick={this.openSelfIntroDrawer} className="selfIntroBtn">
                    <Icon type="question-circle" style={{color: "#c8c8c8", fontSize: "20px"}} theme={'outlined'}/>
                    &nbsp;
                </div> */}
          {quickAskList.map((q, i) => {
            return <QuickAskItem ask={q} key={`qa${i}`} />;
          })}
        </div>
        {/* {this.props.guideStep=== 1 &&
         <div style={{
          position:'fixed',
          top: 400, left: 0,
          width: 300,
          height: 200,
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.5)',
          borderTopWidth: 100,
          borderRightWidth: 'calc(100vw - 450px)',
          borderBottomWidth: 'calc(100vh - 300px)',
          borderLeftWidth: 150
         }}></div>

        } */}
        <Modal
          visible={this.props.guideStep=== 1}
          closable={false}
          maskClosable={false}
          onCancel={()=>this.props.setGuideStep(0)}
          cancelText='跳过（1/4）'
          onOk={()=>this.props.setGuideStep(2)}
          okText='明白了'
          width={'90vw'}
          wrapClassName={commonStyles.myGuideModal}
          okButtonProps ={{type:''}}
          cancelButtonProps = {{type:'link'}}
          style={{top:'calc(' + ((window.visualViewport?.height ?? window.innerHeight) - 250) + 'px)'}}
          >
            <div style={{display:'flex',alignItems:'center'}}>
              <div style={{width:40}}>
                <Avatar size={40} src={require('../../images/video.png')}/>
              </div>
              &nbsp;&nbsp;<p>如果想了解这些类目下的具体知识点，可以点击这里<u>提问</u></p>
            </div>
            <div style={{position:'absolute',bottom:-9,borderTop:'10px solid #6794ff' ,borderRight:'10px solid transparent',borderLeft:'10px solid transparent'}}></div>
        </Modal>
      </>
      );
    }
  }
}

QuickAsk.propTypes = {
  isLoading: propTypes.bool,
  quickAskList: propTypes.array,
  sendMessage: propTypes.func,
  openDrawer: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.QuickAsks.isLoading,
    quickAskList: state.QuickAsks.quickAsks,
    guideStep: state.Dialogs.guideStep
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (msg) => {
      dispatch(sendMessage(msg));
    },
    openDrawer: (param) => {
      dispatch(openDrawer(param));
    },
    setGuideStep:(param)=> {
      dispatch(setGuideStep(param))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickAsk);
