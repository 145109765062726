import React, { Component } from 'react';
import propTypes from 'prop-types';

import formatAnswerHtml from '../formatAnswerHtml';
import FoldableTd from './foldableTd';

import styles from './index.module.less';

function formatTr(trArr, dialogIndex) {
  let head = trArr[0];
  let trHtml = [];
  for (let i in trArr) {
    if (i > 0) {
      let tdArr = trArr[i];
      trHtml.push(
        <tr key={`${dialogIndex}tr${i}`}>
          <td>
            {tdArr.map((d, j) => {
              return (
                <FoldableTd key={`${dialogIndex}tr${i}td${j}`} overflowFold={true}>
                  <div
                    className={styles.tableP}
                    dangerouslySetInnerHTML={{ __html: formatAnswerHtml(head[j] + '：' + d) }}
                  ></div>
                </FoldableTd>
              );
            })}
          </td>
        </tr>,
      );
    }
  }
  return trHtml;
}

class AnswerTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let dialogIndex = this.props.dialogIndex;
    return (
      <table className={styles.table}>
        <tbody>
          {this.props.tableArr[0] && this.props.tableArr[0].length > 1
            ? formatTr(this.props.tableArr, dialogIndex)
            : this.props.tableArr.map((tr, tri) => {
                return (
                  <tr key={`${dialogIndex}tr${tri}`} className={styles.firstBold}>
                    <td>
                      {tr.map((d, i) => {
                        return (
                          <FoldableTd key={`${dialogIndex}tr${tri}td${i}`} overflowFold={true}>
                            <div dangerouslySetInnerHTML={{ __html: formatAnswerHtml(d) }}></div>
                          </FoldableTd>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    );
  }
}

AnswerTable.propTypes = {
  tableArr: propTypes.array,
  dialogIndex: propTypes.string,
};

export default AnswerTable;
