import { combineReducers } from 'redux'

import Dialogs from './reducers/dialogs'
import Faq from './reducers/faq'
import QuickAsks from './reducers/quickAsk'
import Drawer from './reducers/drawer'
import Video from './reducers/video'
import Power from './reducers/power'

const rootReducer = combineReducers({
    Dialogs,
    Faq,
    QuickAsks,
    Drawer,
    Video,
    Power
})
export default rootReducer