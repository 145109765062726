import React, { Component } from 'react';
import 'isomorphic-fetch';
import { Form, Input, Button ,Icon,message} from 'antd';
import utils from '../../utils/utils';
import getUuid from '../../utils/getUuid';
import styles from './index.module.less';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIdAvailable: false,
      userNameAvailable: false,
      loading:false
    };
  }
  componentDidMount() {
    this.onLoginStatus()
  }
  onLoginStatus=()=>{
    if(utils.getCookie('token') && utils.getStorage('userinfo') && utils.getStorage('org-config') && JSON.parse(utils.getStorage('userinfo')).loginFrom === 'manual'){
      this.props.history.push('/index')
    }
  }
  onGetCurrentUser = (token) => {
    let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/v1/get_current_user`
    fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'AUTHORIZATION':token
      },
    })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else if(response.status === 401){
        this.setState({
          loading:false
        },()=>{
          message.error('401错误，登录失败')
        })
      } else if(response.status === 403){
        this.setState({
          loading:false
        },()=>{
          message.error('无访问权限')
        })
      }
    })
    .then(res => {
      if (res.resultCode === 0) {
        // utils.setCookie({
        //   'token':token,
        //   'path':'/',
        //   'Max-Age':(4*365*24*60*60)
        // })
        utils.setStorage('userinfo',{...res.data,'loginFrom':'manual'})
        this.onGetCurrentOrgConfig(token)
        // window.location.reload()
        // if(window.location.href.includes('?ticket=')){
        //   window.location.href = window.location.href.substring(0,window.location.href.indexOf('?ticket='))
        // }else{
        //   this.props.history.push('/index')
        // }
      }else if(res.displayMessage){
        this.setState({
          loading:false
        },()=>{
          message.error(res.displayMessage)
        })
      }
    })
    .catch(e => {
        window.console.log("Oops, error", e)
    })
  }
  onGetCurrentOrgConfig = (token) => {
    let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/org-config/current`
    fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'AUTHORIZATION':token
      },
    })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else if(response.status === 401){
        this.setState({
          loading:false
        },()=>{
          message.error('401错误，登录失败')
        })
      } else if(response.status === 403){
        this.setState({
          loading:false
        },()=>{
          message.error('无访问权限')
        })
      }
    })
    .then(res => {
      if (res.resultCode === 0) {
        utils.setStorage('org-config',JSON.stringify({...res.data}))
        utils.setCookie({
          'token':token,
          'path':'/',
          'Max-Age':(4*365*24*60*60)
        })
        window.location.reload()
      }else if(res.displayMessage){
        this.setState({
          loading:false
        },()=>{
          message.error(res.displayMessage)
        })
      }
    })
    .catch(e => {
        window.console.log("Oops, error", e)
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if(!err && values.userName && values.password){
        this.setState({
          loading:true
        },()=>{
          let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/v1/login`
          let bodyParams = {...values,platform:'LEYAN'}
          fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body:JSON.stringify(bodyParams)
          })
          .then(response => {
            if (response.status === 200) {
              return response.json()
            } else if(response.status === 401){
              this.setState({
                loading:false
              },()=>{
                message.error('401错误，登录失败')
              })
            } else if(response.status === 403){
              this.setState({
                loading:false
              },()=>{
                message.error('无访问权限')
              })
            }
          })
          .then(res=>{
            if(res.resultCode===0){
              this.onGetCurrentUser(res.data)
              // let obj = res.data
              // utils.setCookie({token:obj.token,path:'/','Max-Age':(4*365*24*60*60)})
              // delete obj.token
              // utils.setStorage('userinfo',{...obj,loginFrom:'manual'})
              // window.location.reload()
            }else if(res.displayMessage){
              this.setState({
                loading:false
              },()=>{
                message.error(res.displayMessage)
              })
            }
          }).catch(e => {
            window.console.log("Oops, error", e)
          })
        })
      }
    });
  };
  validateIdInput = (rule, val, callback) => {
    if (val && val.replace(/\r\n/g, '') && val.replace(/\n/g, '') && val.replace(/\s/g, '')) {
      this.setState({
        userIdAvailable: true,
      });
    } else {
      this.setState({
        userIdAvailable: false,
      });
    }
    callback();
    // let r = /^[0-9]*$/;
    // if (!r.test(val) || val?.length > 11) {
    //   this.setState({
    //     userIdAvailable: false,
    //   });
    //   callback('抱歉，学号应为11位数字，请重新输入');
    // } else if (val?.length === 11) {
    //   this.setState({
    //     userIdAvailable: true,
    //   });
    //   callback();
    // } else {
    //   this.setState({
    //     userIdAvailable: false,
    //   });
    //   callback();
    // }
  };
  validateNameInput = (rule, val, callback) => {
    if (val && val.replace(/\r\n/g, '') && val.replace(/\n/g, '') && val.replace(/\s/g, '')) {
      this.setState({
        userNameAvailable: true,
      });
    } else {
      this.setState({
        userNameAvailable: false,
      });
    }
    callback();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div
        className={styles.loginBg}
        style={{
          background: 'url(' + require('../../images/bg@3x.png') + ') no-repeat',
          backgroundSize: '100% auto',
        }}
      >
        <div className={styles.loginBox}>
          {process.env.REACT_APP_WEBSITE_TITLE}系统
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label="账号:" style={{ display: 'flex', margin: '20px 0px' }}>
              {getFieldDecorator('userName', {
                rules: [
                  { validator: this.validateIdInput },
                  // { required: true, message: '请输入账号!' }
                ],
              })(<Input placeholder="请输入账号" />)}
            </Form.Item>
            <Form.Item label="密码:" style={{ display: 'flex' }}>
              {getFieldDecorator('password', {
                rules: [
                  { validator: this.validateNameInput }
                  // { required: true, message: '请输入密码!' }
                ],
              })(<Input placeholder="请输入密码" />)}
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className={styles.loginBtn}
                style={{
                  backgroundColor:
                    this.state.userIdAvailable && this.state.userNameAvailable
                      ? '#4372ff'
                      : '#d4d7e0',
                }}
              >
                {this.state.loading ? <span>登录中&nbsp;<Icon type="loading" /></span> : '登录'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create()(Login);
