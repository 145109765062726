import React, { Component } from 'react';
import propTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import DialogMe from '../dialogMe';
import DialogOther from '../dialogOther/dialogOther';
import { onGetCurrentOrgConfig,getHistory, sendWelcome ,changeMode,playVideo,setGuideStep,loadingFaq,loadingQuickAsk} from '../../redux/actions/home';
import utils from '../../utils/utils';
import Announcer from '../../components/announcer/announcer';
import ManagementEntrance from '../../components/managementEntrance/managementEntrance';
import { Drawer ,Button,Icon,Avatar,Modal} from 'antd';
import styles from '../common.module.less';

const date = new Date(Number(new Date()));

const time = (
  <div style={{ textAlign: 'center', marginTop: 10 }}>
    <p
      style={{
        color: '#c8c8c8',
        width: 45,
        padding: 4,
        backgroundColor: '#fff',
        borderRadius: 5,
        margin: 'auto',
        fontSize:12
      }}
    >
      {date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:
      {date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}
    </p>
  </div>
);

class DialogSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseDownY: '',
      loadTag: false,
      viewHeight:visualViewport?.height ?? window.innerHeight,
    };
  }
  resizeObserver = null
  divRef = React.createRef(null)

  componentDidMount() {
    if (!utils.getStorage('userinfo')) {
      window.location.href = window.location.origin
    }else{
      try{
        let userinfo = JSON.parse(utils.getStorage('userinfo'))
        if(this.props.managementOnMobileRoles?.includes(userinfo?.maxRole)){
          this.setState({
            managementOnMobileAvailable:true
          })
        }
      }catch(e){
        console.log(e)
      }
    }
    this.setState({
      touch:utils.isMobile() ? true : false
    })
    this.props.getInitHistory({ queryUnread: true, endDatetime:Number(new Date())});
    this.resizeObserver = new ResizeObserver(entries => {
      let height = entries[0].contentRect.height
      this.setState({
        videoHeight: height,
      });
    });
    this.resizeObserver.observe(this.divRef.current)
    window.addEventListener('resize',this.setFrameHeight)
    // if(utils.isIOS() && window.visualViewport){
    //   window.visualViewport.addEventListener('resize',this.setFrameHeight)
    //   window.visualViewport.addEventListener('scroll',this.setFrameHeight)
    // }
  }
  componentWillUnmount(){
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener('resize',this.setFrameHeight)
    // if(utils.isIOS() && window.visualViewport){
    //   window.visualViewport.removeEventListener('resize',this.setFrameHeight)
    //   window.visualViewport.removeEventListener('scroll',this.setFrameHeight)
    // }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.chat !== this.props.chat) {
      const anchorElement = document.getElementById('anchor')
      // const anchorElement2 = document.getElementById('welcome')
      if(prevProps.chat.cursor===-1&&this.props.chat.cursor!==-1){
        this.setState({
          listenScroll:true
        })
      }else{
        this.setState({
          listenScroll:false
        })
      }
      // console.log(prevProps.chat.cursor,this.props.chat.cursor)
      if(anchorElement&&prevProps.chat.cursor===-1){
        setTimeout(()=>{
          anchorElement.scrollIntoView()
        })
        this.setState({
          scrollData:{
            parentClientHeight:this.dom.clientHeight,
            parentScrollHeight:this.dom.scrollHeight,
            parentScrollTop:this.dom.scrollTop,
          }
    
        })
      }
      // else if(anchorElement2&&prevProps.chat.cursor===-1&&this.props.chat.cursor!==-1){
        // setTimeout(()=>{
        //   anchorElement2.scrollIntoView()
        // },50)
      // }
      else if(this.props.chat.cursor!==-1){
        let b = this.dialogBox;
        if (b) {
          setTimeout(()=>{
            b.scrollIntoView();
          },200)
        }  
      }
    }
  }
  handleScroll() {
    if(this.state.listenScroll){
      if (this.time && Date.now() - this.time < 100) return
      this.time = Date.now()
      if(this.dom){
          this.setState({
            scrollData:{
              parentClientHeight:this.dom.clientHeight,
              parentScrollHeight:this.dom.scrollHeight,
              parentScrollTop:this.dom.scrollTop,
            }
          })
      }
    }
  }
  setFrameHeight=()=>{
    this.setState({
      viewHeight:window.visualViewport?.height ?? window.innerHeight
    })
  }
  startEvent=(e)=> {
    if (this.dom?.scrollTop === 0) {
      this.setState({ 
        mouseDownY: this.state.touch ? e.touches[0].pageY : e.clientY 
      });
    }
  }
  moveEvent=(e)=> {
    if (this.time && Date.now() - this.time < 300) return;
    this.time = Date.now();
    if (this.dom?.scrollTop === 0 && this.state.mouseDownY && (this.props.guideStep=== 0 || this.props.guideStep=== -1)) {
      let Y = this.state.touch ? e.touches[0].pageY : e.clientY
      if ( Y - this.state.mouseDownY > 15) {
        this.setState({
          loadTag: true,
        },()=>{
          this.setState({
            mouseDownY: '',
          });
          if (this.props.chat.cursor === 1) {
            setTimeout(() => {
              this.setState({
                loadTag: false,
              });
            }, 1000);
          } else {
            setTimeout(() => {
              let params = { queryUnread: false, endDatetime:this.props.chat.timeCursor}
              if(this.props.chat.cacheDialogs?.length>0){
                params.cache = this.props.chat.cacheDialogs
              }
              this.props.getHistory(params)
              this.setState({
                loadTag: false,
              });
            }, 400);
          }
        });
      }
    }
  }
switchMode=(e)=>{
  e.nativeEvent.stopImmediatePropagation()
  this.props.playVideo({videoType: "default"})
  this.props.changeMode(this.props.mode === 'video' ? 'text' : 'video')
}
  render() {
    const loadHistory = (
      <div style={{ padding: '10px 0 0', textAlign: 'center' }}>
        <button
          style={{
            border: 'none',
            color: '#c8c8c8',
            width: 120,
            padding: 4,
            backgroundColor: '#fff',
            borderRadius: 5,
            margin: 'auto',
            marginBottom: -20,
            fontSize:12
          }}
        >
          {this.props.chat.cursor === 1 ? '已为您加载所有' : '正在查询历史记录'}
        </button>
      </div>
    );

    return (
      <div
        className={styles.chatBox}
        style={{
          height:this.state.viewHeight,
          paddingTop:(this.state.managementOnMobileAvailable ? 30 : 0) + (this.props.mode === 'video' ? this.state.videoHeight : 0),
          paddingBottom:this.props.quickAskList?.length === 0 ? 75 : 115
          // background: 'url(' + require('../../images/video-bg.png') + ') no-repeat',
          // backgroundSize: '100% auto',
        }}
      >
        <div
          className={styles.dialogBox}
          onMouseDown={(e) => this.startEvent(e)}
          onMouseMove={(e) => this.moveEvent(e)}
          onTouchStart={(e) => this.startEvent(e)}
          onTouchMove={(e) => this.moveEvent(e)}
          onScroll={() => this.handleScroll()}
          ref={(dom) => {
            this.dom = dom;
          }}
        >
          {this.state.managementOnMobileAvailable && <ManagementEntrance/>}
          <Drawer
            getContainer={false}
            placement='top'
            visible={this.props.mode === 'video'}
            mask={false}
            closable={false}
            bodyStyle={{padding:0}}
            height={this.state.videoHeight}
            zIndex={998}
            style={{top:this.state.managementOnMobileAvailable ? 30 : 0}}
          >
            <div ref={this.divRef} className={styles.myVideoPlayer}>
              <Announcer/>
            </div>
          </Drawer>
          <div className={styles.myAnchorButton} style={{top:this.state.managementOnMobileAvailable ? 40 :10 ,zIndex: this.props.guideStep=== 4 ? 1001 :'',pointerEvents:this.props.guideStep=== 4 ? 'none' : ''}}>
            <Button shape="round" type='link' onClick={this.switchMode}>
              <Avatar size={21} src={this.props.mode === 'video' ? require('../../images/video.png') : require('../../images/video-disabled.png')} />&nbsp;切换模式
            </Button>
            <Modal
              visible={this.props.guideStep=== 4}
              closable={false}
              maskClosable={false}
              width={'90vw'}
              wrapClassName={styles.myGuideModal}
              style={{top:this.state.managementOnMobileAvailable ? 80 : 50}}
              footer={[
                <Button onClick={()=>this.props.setGuideStep(0)}>
                  明白了
                </Button>
              ]}
              >
                <div style={{display:'flex',alignItems:'center'}}>
                  <div style={{width:40}}>
                    <Avatar size={40} src={require('../../images/video.png')}/>
                  </div>
                  &nbsp;&nbsp;<p>虚拟辅导员有【虚拟人播报】和【纯文字对话】两种互动模式，可以点击这里<u>切换</u></p>
                </div>
                <div style={{position:'absolute',top:-9,right:30,borderBottom:'10px solid #709dff' ,borderRight:'10px solid transparent',borderLeft:'10px solid transparent'}}></div>
            </Modal>

          </div>
          {this.state.loadTag && loadHistory}
          {this.props.chat.dialogs.map((dialog, index) => {
            if (dialog.from === 'system') {
              if(dialog.message.from === 'feedback' || dialog.message.from === 'notice'){
                return (
                  <div id='anchor'></div>
                )
              }
              else if (dialog.message === 'date') {
                return <span key={`time_${index}`} id='welcome'>{time}</span>;
              } else if (dialog.message == 'viewFlag') {
                return (
                  <div
                    ref={(m) => {
                      this.dialogBox = m;
                    }}
                    key={`d${index}`}
                  ></div>
                );
              }
            } else if (dialog.from === 'me') {
              return (
                <DialogMe content={dialog.message} key={`d${index}`} dialogKey={`d${index}`} />
              );
            } else if (dialog.from === 'other') {
              return (
                <DialogOther 
                  scrollData={this.state.scrollData} 
                  content={dialog.message} 
                  key={`d${dialog.message.chatId}-${index}`} 
                  dialogKey={`d${dialog.message.chatId}-${index}`} />
              );
            } else return null;
          })}
        </div>
      </div>
    );
  }
}

DialogSection.propTypes = {
  getHistory: propTypes.func.isRequired,
  getInitHistory: propTypes.func.isRequired,
  chat: propTypes.shape({
    isLoading: propTypes.bool.isRequired,
    historyIsLoading: propTypes.bool.isRequired,
    cursor: propTypes.number.isRequired,
    timeCursor: propTypes.number.isRequired,
    dialogs: propTypes.arrayOf(
      propTypes.shape({
        from: propTypes.oneOf(['other', 'me', 'system']).isRequired,
        message: propTypes.oneOfType([propTypes.object, propTypes.string]).isRequired,
      }),
    ).isRequired,
  }),
};

const mapStateToProps = (state) => {
  return {
    chat: state.Dialogs,
    mode : state.Video.mode,
    guideStep: state.Dialogs.guideStep,
    managementOnMobileRoles: state.Power.managementOnMobileRoles,
    quickAskList: state.QuickAsks.quickAsks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInitHistory: (params) => {
      onGetCurrentOrgConfig();
      dispatch(loadingQuickAsk());
      dispatch(getHistory(params))
          .then(dispatch(sendWelcome()),dispatch(loadingFaq()))
    },
    getHistory: (params) => {
      dispatch(getHistory(params));
    },
    changeMode: (params) => {
      dispatch(changeMode(params))
    },    
    playVideo:(param) => {
      dispatch(playVideo(param))
    },
    setGuideStep:(param)=> {
      dispatch(setGuideStep(param))
    }

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogSection);
