import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tree } from 'antd';

import { startSendingMessage, finishSendingMessage, closeDrawer } from '../../redux/actions/home';
import styles from './index.module.less';

const DirectoryTree = Tree.DirectoryTree;
const TreeNode = Tree.TreeNode;

function formatTreeNodeData(arr, str) {
  return arr.map((i) => {
    return Object.assign({}, i, { selectedStr: str + i.nodeValue });
  });
}

class AnswerTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: formatTreeNodeData(props.content.answerObject, ''),
      supplementQuestionGroupId: props.content.supplementQuestionGroupId,
    };
    this.onLoadData = this.onLoadData.bind(this);
    this.renderTreeNodes = this.renderTreeNodes.bind(this);
    this.handleNodeSelect = this.handleNodeSelect.bind(this);
  }
  handleNodeSelect(selectedKeys, e) {
    if (e.node.props.isLeaf) {
      this.onLoadData(e.node);
    }
  }

  onLoadData(treeNode) {
    let _this = this;
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/qa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        /*eslint-disable no-undef*/
        body: JSON.stringify({
          userId: uuid,
          supplementQuestionGroupId: _this.state.supplementQuestionGroupId,
          selfId: treeNode.props.eventKey,
        }),
        /*eslint-enable*/
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.answer) {
            let answer = JSON.parse(res.answer);
            if (answer[0].componentCode == '9') {
              treeNode.props.dataRef.children = formatTreeNodeData(
                answer[0].answerObject,
                treeNode.props.selectedStr + '且',
              );
              _this.setState({
                treeData: [..._this.state.treeData],
              });
            } else {
              this.props.startSendingMessage(treeNode.props.selectedStr);
              _this.props.closeDrawer();
              if (answer[1]) {
                if (answer[1].componentCode == 8) {
                  answer[0].mark = answer[1];
                  answer[0].mark.supplementQuestionGroupId = res.supplementQuestionGroupId;
                  answer[0].mark.userContextId = res.userContextId;
                }
              }
              _this.props.finishSendingMessage(answer[0]);
            }
            resolve();
          } else {
            window.console.log('Oops, error', res);
            reject();
          }
        })
        .catch((e) => {
          window.console.log('Oops, error', e);
          reject();
        });
    });
  }

  renderTreeNodes(data) {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.nodeValue}
            isLeaf={!item.isHaveChild}
            key={item.selfId}
            dataRef={item}
            icon={null}
            selectedStr={item.selectedStr}
            selectable={!item.isHaveChild}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          title={item.nodeValue}
          isLeaf={!item.isHaveChild}
          key={item.selfId}
          dataRef={item}
          icon={null}
          selectedStr={item.selectedStr}
          selectable={!item.isHaveChild}
        />
      );
    });
  }

  render() {
    return this.state.treeData.length ? (
      <DirectoryTree
        loadData={this.onLoadData}
        showLine={true}
        showIcon={false}
        onSelect={this.handleNodeSelect}
        className={styles.customTree}
      >
        {this.renderTreeNodes(this.state.treeData)}
      </DirectoryTree>
    ) : (
      <p>loading...</p>
    );
  }
}

AnswerTree.propTypes = {
  isLoading: propTypes.bool,
  open: propTypes.bool,
  content: propTypes.object,
  closeDrawer: propTypes.func,
  startSendingMessage: propTypes.func,
  finishSendingMessage: propTypes.func,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.Drawer.isLoading,
    open: state.Drawer.open,
    content: state.Drawer.content.content,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSendingMessage: (msg) => {
      dispatch(startSendingMessage(msg));
    },
    finishSendingMessage: (msg) => {
      dispatch(finishSendingMessage(msg));
    },
    closeDrawer: () => {
      dispatch(closeDrawer());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerTree);
