import React, { Component } from 'react';
import { Player,ControlBar,Shortcut,BigPlayButton,LoadingSpinner } from "video-react";
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { playVideo,setNoticeVideos } from '../../redux/actions/home';
import posterImg from '../../images/poster.png';
import localVideoSrc from '../../images/default.mp4';
import "video-react/dist/video-react.css";
import utils from '../../utils/utils';

class Announcer extends Component {
    constructor(props){
        super(props);
        this.state = {
            videoSrc:localVideoSrc,
            poster:posterImg
        }
    }
    componentDidMount() {
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
        try{
            let vSrc = JSON.parse(utils.getStorage('org-config')).videoStandbyUrl
            let imgSrc = JSON.parse(utils.getStorage('org-config')).videoStandbyImgUrl
            let i = new Image()
            i.src = imgSrc
            i.onload = () => {
                this.setState({
                    poster:imgSrc
                })    
            }
            this.setState({
                videoSrc:vSrc,
            })
        }catch(e){
            console.log('error',e)
        }
      }
    componentDidUpdate(prevProps,prevState){
        if((prevProps.video?.videoUrl !== this.props.video?.videoUrl) || 
        (prevProps.video?.play !== this.props.video?.play) || 
        (prevProps.video?.chatId !== this.props.video?.chatId)
        ){
                if(this.props.video?.play===false){
                    if(this.props.video.videoType === 'initNotice'){
                        this.defaultPlay()
                    }else{
                        this.props.playVideo({videoType: "default"})
                    }
                }else{
                    this.setState({
                        videoSrc:this.props.video?.videoUrl,
                    },()=>{
                        this.load()
                    })
                }
                if(this.props.video?.videoType==='default'){
                    this.setState({
                        loop:true
                    })
                }else{
                    this.setState({
                        loop:false
                    })
                }
        }
        if(this.props.videoList?.length>0 && prevProps.videoList?.length === 0){
            this.props.playVideo({...this.props.videoList[0],init:true})
        }
        if(this.props.videoList?.length !== prevProps.videoList?.length && prevProps.videoList?.length === this.props.playedVideoList.length && this.props.video?.videoType === 'default'){
            this.defaultPlay()
        }
    }
    handleStateChange(state,prevState) {
        // if(this.props.video?.init && !prevState.hasStarted && state.hasStarted 
        //     // && utils.isMobile()
        //     ){
        //     // document.removeEventListener('touchstart',this.initPlay)
        //     // // document.removeEventListener('click',this.initPlay)
        //     // console.log('aa')
        //     if(utils.isMobile()){
        //         // document.removeEventListener('touchend',this.initPlay)
        //         document.removeEventListener('click',this.initPlay)
        //         console.log('aa')
        //     }else{
        //         document.removeEventListener('click',this.initPlay)
        //         console.log('bb')
        //     }
    
        // }
        this.setState({
          player: state
        });
        if(this.props.video?.init && state.currentSrc !== prevState.currentSrc){
            if(prevState.hasStarted || state.hasStarted){
                this.props.playVideo({play:true,...this.props.video})
            }else{
                if(utils.isMobile()){
                    // console.log('mobile')
                    // document.addEventListener('touchend',this.initPlay)
                    document.addEventListener('click',this.initPlay)
                }else{
                    // console.log('pc')
                    document.addEventListener('click',this.initPlay)
                }
            }
        }
        if(state.ended&&this.props.video?.videoType!=='default'){
            this.defaultPlay()
        }
      }
    defaultPlay =()=>{
        if(this.props.videoList.length > this.props.playedVideoList.length){
            let arr = this.props.videoList.filter(item=>!this.props.playedVideoList.map(i=>i.chatId).includes(item.chatId))
            if(arr && arr.length >0){
                this.props.playVideo({play:true,...arr[0]})
            }
            else{
                this.props.playVideo({videoType: "default"})
            }
        }
        else{
            this.props.playVideo({videoType: "default"})
        }
    }
    initPlay=(e)=> {
        // console.log(e)
        // if(!(e.path || (e.composedPath && e.composedPath())).some(i=>i.className?.toString().match('noVideoListener'))){
            this.play();
            // console.log('play')
        // }
        this.props.playVideo({play:true,...this.props.video})
        if(utils.isMobile()){
            // document.removeEventListener('touchstart',this.initPlay)
            document.removeEventListener('click',this.initPlay)
        }else{
            document.removeEventListener('click',this.initPlay)
        }
      }
      play=()=> {
        this.player.play();
      }
      pause=()=> {
        this.player.pause();
      }
    
      load=()=> {
        this.player.load();
      }
    render(){
        return (
            // <div className={styles.myVideoPlayer}>
                <Player 
                ref={player => {
                    this.player = player;
                }}
                autoPlay
                muted={this.props.mode === 'text'}
                poster={this.state.poster}
                playsInline={true}
                aspectRatio='720:360'
                // aspectRatio='720:1280'
                loop={this.state.loop}
                >
                    <source src={this.state.videoSrc} />
                    <Shortcut clickable={false} dblclickable={false}/>
                    <BigPlayButton position="center"/>
                    <ControlBar className="controlBar" autoHide={true} disableDefaultControls={true}/>
                    <LoadingSpinner />
                </Player>
            // </div>
        )
    }
}
// Announcer.propTypes = {
//     content: propTypes.object,
// };
  
const mapStateToProps = (state) => {
    return {
      video: {...state.Video.video},
      videoList:[...state.Video.videoList],
      playedVideoList:state.Video.playedVideoList,
      mode:state.Video.mode
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        setNoticeVideos:(params)=>{
            dispatch(setNoticeVideos(params))
        },
        playVideo: (params) => {
            dispatch(playVideo(params));
          },
        };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Announcer)