import {PLAY_VIDEO, INIT_NOTICES,CHANGE_MODE ,VIDEO_LISTENER, PLAYED_VIDEO} from "../actions/home"
import utils from '../../utils/utils';

const initState = {
    video: {},
    videoList:[],
    playedVideoList:[],
    mode:utils.getStorage('mode') ? utils.getStorage('mode') : 'video',
    videoListener:false
}

export default function reducer(state = initState, action) {
    switch (action.type) {
        case PLAY_VIDEO:
            return {
                ...state,
                video: action.video
            }
        case PLAYED_VIDEO:
            let arr = state.playedVideoList.map(item => item.chatId).includes(action.playedVideo.chatId) ? [...state.playedVideoList] : [...state.playedVideoList,action.playedVideo] 
            // console.log(arr)
            return {
                ...state,
                playedVideoList:arr
            }
        case INIT_NOTICES:
            let videoList = !action.video ? [] : state.videoList.map(item=>item.videoUrl).includes(action.video.videoUrl) ? [...state.videoList] : [...state.videoList, action.video]
            return {
                ...state,
                videoList: videoList
            }
        case CHANGE_MODE:
            utils.setStorage('mode',action.mode)
            return {
                ...state,
                mode: action.mode
            }
        case VIDEO_LISTENER:
            return {
                ...state,
                videoListener:action.state
            }
        default:
            return state
    }
}