import React, { Component } from 'react';
import propTypes from 'prop-types';

import { ReactComponent as ExpandSvg } from '../../images/icon/expand.svg';
import { ReactComponent as ShrinkSvg } from '../../images/icon/shrink.svg';
import styles from './index.module.less';

class FoldableTd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fold: false,
      isOverFlow: false,
    };
    this.unfoldBubble = this.unfoldBubble.bind(this);
    this.foldBubble = this.foldBubble.bind(this);
  }

  unfoldBubble() {
    this.setState({
      fold: false,
    });
  }
  foldBubble() {
    this.setState({
      fold: true,
    });
  }

  componentDidMount() {
    let height = this.divElement.clientHeight;
    if (height > 70) {
      if (this.props.overflowFold) {
        this.setState({
          fold: true,
          isOverFlow: true,
        });
      }
    }
  }

  render() {
    return (
      <div
        className={this.state.fold ? styles.foldTd : styles.unfoldTd}
        ref={(divElement) => (this.divElement = divElement)}
      >
        <div className={styles.overflowBox}>
          {this.props.children}
          {this.state.isOverFlow ? (
            <div>
              <p style={{ fontSize: '14px', color: '#3C6BC0' }} onClick={this.foldBubble}>
                收起
                <ShrinkSvg />
              </p>
            </div>
          ) : null}
        </div>
        <div className={styles.loadMore}>
          <p style={{ fontSize: '14px', color: '#3C6BC0' }} onClick={this.unfoldBubble}>
            展开查看全部
            <ExpandSvg />
          </p>
        </div>
      </div>
    );
  }
}

FoldableTd.propTypes = {
  children: propTypes.node,
  overflowFold: propTypes.bool,
};

export default FoldableTd;
