import React, { Component } from 'react';
import propTypes from 'prop-types';

import connect from 'react-redux/es/connect/connect';
import lottie from 'lottie-web';
import likeJson from '../../lottieJson/like.json';
import getUuid from '../../utils/getUuid';
import {Modal,Avatar} from 'antd';
import { mark, openDrawer,playVideo,setGuideStep } from '../../redux/actions/home';

import { ReactComponent as FeedbackSvg } from '../../images/icon/feedback.svg';
import styles from '../dialogSection/index.module.less';
import commonStyles from '../common.module.less'

class MarkAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  // Ref = React.createRef();
  iconDivRef = React.createRef(null)
  componentDidMount(){
    this.setState({
      trigerGuide:this.props.trigerGuide
    })
  }
  componentDidUpdate(prevProps,prevState){
    if(this.props.guideStep === 2 && prevProps.guideStep === (this.props.quickAsks?.length === 0 ? -1 : 1) && this.state.trigerGuide){
      if(this.iconDivRef.current){
        // console.log(this.iconDivRef.current.getBoundingClientRect())
        setTimeout(()=>{
          this.setState({
            modalX:this.iconDivRef.current.getBoundingClientRect().x + 2,
            modalY:this.iconDivRef.current.getBoundingClientRect().y -135
          })
        })
      }
    }
  }
openDislikeDrawer=(param)=> {
    this.props.playVideo({videoType: "default"})
    this.props.openDrawer(param);
  }
  handleFeedback=()=> {
    // if (!this.state.selectedItem) {
      // lottie.loadAnimation({
      //   container: document.getElementById(this.Ref.current.id),
      //   renderer: 'svg',
      //   loop: false,
      //   autoplay: true,
      //   animationData: likeJson,
      // });
      this.openDislikeDrawer({
        type: 'intro',
        userId: getUuid(),
        supplementQuestionGroupId: this.props.markParams.supplementQuestionGroupId,
        userContextId: this.props.markParams.userContextId,
        gradeType: 1,
        grade: -1.0,
        gradeComment: '',
      });
  }
  render() {
    return (
      <div className={styles.remarkDiv}>
        {/* <div
          style={{ width: 25, margin: '-5.4px 0px', height: this.state.marked ? 50 : 0 }}
          id={new Date().getTime()}
          ref={this.Ref}
        ></div> */}
        {
          this.props.answersCommented.includes(this.props.markParams.supplementQuestionGroupId) ? null :
          <div ref={this.iconDivRef} className={this.props.guideStep=== 2 && this.state.trigerGuide  ? styles.maskFeedbackIconDiv : styles.feedbackIconDiv}>
            <FeedbackSvg onClick={this.handleFeedback} />
          </div>
        }
        <Modal
          visible={this.props.guideStep=== 2 && this.state.trigerGuide}
          closable={false}
          maskClosable={false}
          onCancel={()=>this.props.setGuideStep(0)}
          cancelText={this.props.quickAsks?.length === 0 ? '跳过（1/3）' : '跳过（2/4）'}
          onOk={()=>this.props.setGuideStep(3)}
          okText='明白了'
          width={'90vw'}
          wrapClassName={commonStyles.myGuideModal}
          okButtonProps ={{type:''}}
          cancelButtonProps = {{type:'link'}}
          style={{top:this.state.modalY ?? 0,right:'-4vw'}}      
        >
          <div style={{display:'flex',alignItems:'center'}}>
            <div style={{width:40}}>
              <Avatar size={40} src={require('../../images/video.png')}/>
            </div>
            &nbsp;&nbsp;<p>如果没有解决你的问题，或对答案有所疑问，可以点击这里给辅导员<u>留言</u></p>
          </div>
          <div style={{position:'absolute',left:`calc(${this.state.modalX}px - 9vw)`,bottom:-9,borderTop:'10px solid #4e7cff' ,borderRight:'10px solid transparent',borderLeft:'10px solid transparent'}}></div>               
          
        </Modal>
      </div>
    );
  }
}

MarkAnswer.propTypes = {
  mark: propTypes.func,
  markParams: propTypes.object,
  answersCommented: propTypes.array,
  openDrawer: propTypes.func,
};
const mapStateToProps = (state) => {
  return {
    answersCommented:state.Dialogs.answersCommented,
    guideStep: state.Dialogs.guideStep,
    quickAsks: state.QuickAsks.quickAsks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mark: (msg) => {
      dispatch(mark(msg));
    },
    openDrawer: (param) => {
      dispatch(openDrawer(param));
    },
    playVideo:(param) => {
      dispatch(playVideo(param))
    },
    setGuideStep:(param)=> {
      dispatch(setGuideStep(param))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkAnswer);
