import React, { Component } from 'react';
import 'isomorphic-fetch';
import { Form, Input, Button ,Icon,message} from 'antd';
import utils from '../../utils/utils';
import getUuid from '../../utils/getUuid';
import styles from './index.module.less';

class Cas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false
    };
  }
  componentDidMount() {
    this.onLoginStatus()
  }
  onLoginStatus=()=>{
    if(utils.getCookie('token') && utils.getStorage('userinfo') && utils.getStorage('org-config')){
      this.props.history.push('/index')
    }
    else if(window.location.href.includes('ticket')){
      this.setState({
        loading:true
      },()=>{
        this.onCAS()
      })
    }else{
      this.setState({
        loading:true
      },()=>{
        this.onGetAuthenticationUrl()
      })
      // window.location.href = `${utils.isMobile() ? 'http://ehall.sbs.edu.cn/iap/login?service=' : 'https://cas.sbs.edu.cn/cas/login?service='}${process.env.REACT_APP_WEBSITE_URL}`
    }
  }
  onGetAuthenticationUrl = () => {
    let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/v1/authentication_url`
    let bodyParams = {
      userEnv:utils.isMobile() ? 'MOBILE' : 'PC',
      visitingLink:process.env.REACT_APP_WEBSITE_URL
    }
    let paramsArray = [];
    Object.keys(bodyParams).forEach(key => paramsArray.push(key + '=' + bodyParams[key]))
    if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
    } else {
        url += '&' + paramsArray.join('&')
    }
    fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8'
      },
      // body:JSON.stringify(bodyParams)
    })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else if(response.status === 401){
        this.setState({
          loading:false
        },()=>{
          message.error('401错误，登录失败')
        })
      } else if(response.status === 403){
        this.setState({
          loading:false
        },()=>{
          message.error('无访问权限')
        })
      }
    })
    .then(res=>{
      if(res.resultCode === 0){
        window.location.href = res.data
      }else if(res.displayMessage){
        this.setState({
          loading:false
        },()=>{
          message.error(res.displayMessage)
        })
      }
    }).catch(e => {
      window.console.log("Oops, error", e)
    })

  }
  onGetCurrentUser = (token) => {
    let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/v1/get_current_user`
    fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'AUTHORIZATION':token
      },
    })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else if(response.status === 401){
        this.setState({
          loading:false
        },()=>{
          message.error('401错误，登录失败')
        })
      } else if(response.status === 403){
        this.setState({
          loading:false
        },()=>{
          message.error('无访问权限')
        })
      }
    })
    .then(res => {
      if (res.resultCode === 0) {
        // utils.setCookie({
        //   'token':token,
        //   'path':'/',
        //   'Max-Age':(4*365*24*60*60)
        // })
        utils.setStorage('userinfo',{...res.data,'loginFrom':'cas'})
        this.onGetCurrentOrgConfig(token)
        // window.location.href = ('/')
        // if(window.location.href.includes('?ticket=')){
        //   window.location.href = window.location.href.substring(0,window.location.href.indexOf('?ticket='))
        // }else{
        //   this.props.history.push('/index')
        // }
      }else if(res.displayMessage){
        this.setState({
          loading:false
        },()=>{
          message.error(res.displayMessage)
        })
      }
    })
    .catch(e => {
        window.console.log("Oops, error", e)
    })
  }
  onGetCurrentOrgConfig = (token) => {
    let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/org-config/current`
    fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'AUTHORIZATION':token
      },
    })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else if(response.status === 401){
        this.setState({
          loading:false
        },()=>{
          message.error('401错误，登录失败')
        })
      } else if(response.status === 403){
        this.setState({
          loading:false
        },()=>{
          message.error('无访问权限')
        })
      }
    })
    .then(res => {
      if (res.resultCode === 0) {
        utils.setStorage('org-config',JSON.stringify({...res.data}))
        utils.setCookie({
          'token':token,
          'path':'/',
          'Max-Age':(4*365*24*60*60)
        })
        window.location.href = ('/')
      }else if(res.displayMessage){
        this.setState({
          loading:false
        },()=>{
          message.error(res.displayMessage)
        })
      }
    })
    .catch(e => {
        window.console.log("Oops, error", e)
    })
  }
  onCAS=()=>{
    let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/v1/generate_token`
    let params = (new URL(window.location.href)).searchParams;
    let bodyParams = {
      serviceUrl:process.env.REACT_APP_WEBSITE_URL,
      terminalType:utils.isMobile() ? 'MOBILE' : 'PC',
      casTicket:params.get('ticket')
    }
    // let s = window.location.href
    // let ticket = s.substring(s.indexOf('ticket=')+7)
    // let params = {
    //   casTicket:ticket.includes('#/') ? ticket.slice(0,-2) : ticket,
    //   serviceUrl:process.env.REACT_APP_WEBSITE_URL,
    //   route:utils.isMobile() ? 'iss' : 'sxy'
    // }
    // let paramsArray = [];
    // Object.keys(params).forEach(key => paramsArray.push(key + '=' + params[key]))
    // if (url.search(/\?/) === -1) {
    //     url += '?' + paramsArray.join('&')
    // } else {
    //     url += '&' + paramsArray.join('&')
    // }
    fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8'
      },
      body:JSON.stringify(bodyParams)
    })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else if(response.status === 401){
        this.setState({
          loading:false
        },()=>{
          message.error('401错误，登录失败')
        })
      } else if(response.status === 403){
        this.setState({
          loading:false
        },()=>{
          message.error('无访问权限')
        })
      }
    })
    .then(res=>{
      if(res.resultCode === 0){
        if(res.data === ''){
          this.onGetAuthenticationUrl()
        }else if(res.data){
          this.onGetCurrentUser(res.data)
        }
        // utils.setCookie(
        //   {
        //     token:res.data,
        //     path:'/',
        //     'Max-Age':(4*365*24*60*60)
        //   }
        // )
        // utils.setStorage('userinfo',{...obj,loginFrom:'cas'})
        // if(window.location.href.includes('?ticket=')){
        //   window.location.href = window.location.href.substring(0,window.location.href.indexOf('?ticket='))
        // }else{
        //   this.props.history.push('/index')

        // }
      }else if(res.displayMessage){
        this.setState({
          loading:false
        },()=>{
          message.error(res.displayMessage)
        })
      }
    }).catch(e => {
      window.console.log("Oops, error", e)
    })
  }
  render() {
    return (
      <div
        className={styles.loginBg}
        style={{
          background: 'url(' + require('../../images/bg@3x.png') + ') no-repeat',
          backgroundSize: '100% auto',
        }}
      >
        <div className={styles.loginBox}>
          {process.env.REACT_APP_WEBSITE_TITLE}系统<br/><br/>
          {this.state.loading && <span>登录中&nbsp;<Icon type="loading" /></span>}
        </div>
      </div>
    );
  }
}

export default Form.create()(Cas);
