export default function formatAnswerHtml(orgStr) {
    var telRE = new RegExp(/<tel>([0-9]|-)+<\/tel>/g)
    var emailRE = new RegExp(/<email>([0-9A-Za-z]|-|_|\.|@)+<\/email>/g)
    var urlRE = new RegExp(/<url>([0-9A-Za-z]|-|_|\.|\/|:)+<\/url>/g)
    let s
    while((s=telRE.exec(orgStr)) !=null ) {
        let content = s[0].replace('<tel>', '').replace('</tel>', '')
        orgStr = orgStr.replace(s[0], `<a href="tel:${content}">${content}</a>`)
    }
    while((s=emailRE.exec(orgStr)) !=null ) {
        let content = s[0].replace('<email>', '').replace('</email>', '')
        orgStr = orgStr.replace(s[0], `<a href="mailto:${content}">${content}</a>`)
    }
    while((s=urlRE.exec(orgStr)) !=null ) {
        let content = s[0].replace('<url>', '').replace('</url>', '')
        if(!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(content)){
            orgStr = orgStr.replace(s[0], `<a href="${content}" target="_blank">${content}</a>`)
        }
        orgStr = orgStr.replace(s[0], `${content}`)
    }
    // 换行符 转换为<br/>
    orgStr = orgStr.replace(/(\n\n\d\.) /g,"$1").replace(/\n./g,(match)=>{
        return `\n${match}`
    })
    return orgStr
}