import {START_LOADING_QUICKASK,FINISH_LOADING_QUICKASK} from "../actions/home"

const initState = {
    isLoading: false,
    quickAsks: [
        // "学籍",
        // "住宿服务",
        // "在籍证明",
        // "学生社团",
        // "成绩",
        // "奖学金",
        // "勤工助学"
    ]
}

export default function reducer(state = initState,action) {
    switch (action.type) {
        case START_LOADING_QUICKASK:
            return {
                ...state,
                isLoading: true
            }
        case FINISH_LOADING_QUICKASK:
            return {
                ...state,
                quickAsks:action.quickAsks ?? [],
                isLoading:false
            }
        default:
            return state
    }
}