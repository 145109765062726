import reduxLogger from 'redux-logger'
import reduxThunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducer'

export default function configureStore() {
    if(process.env.ENV_NAME==='stg' || process.env.ENV_NAME==='feature') {
        return createStore(
            rootReducer,
            applyMiddleware(reduxThunk, reduxLogger)
        )
    } else {
        return createStore(
            rootReducer,
            applyMiddleware(reduxThunk)
        )
    }
}