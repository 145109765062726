import React, {Component} from 'react'
import propTypes from 'prop-types'
import {finishSendingMessage} from "../../redux/actions/home"
import connect from "react-redux/es/connect/connect"

import styles from '../../pages/home/index.module.less'

class Finally extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }
    handleClick() {
        this.props.finishSendingMessage({componentCode: "1", answerObject: this.props.backupStr})
    }
    render() {
        return (
            <a href="javascript:void(0)" onClick={this.handleClick} className={styles.navigation}>
                <p>都不是</p>
            </a>
        )
    }
}

Finally.propTypes = {
    backupStr: propTypes.string,
    finishSendingMessage: propTypes.func
}

const mapDispatchToProps = (dispatch) => {
    return {
        finishSendingMessage: (msg) => {
            dispatch(finishSendingMessage(msg))
        }
    }
}

export default connect( null, mapDispatchToProps )(Finally)