import {START_SENDING_MESSAGE, FINISH_SENDING_MESSAGE, START_LOADING_HISTORY, FINISH_LOADING_HISTORY, SEND_WELCOME,MARK_ANSWER,SET_CACHEDIALOGS,SET_GUIDE_STEP} from "../actions/home"

const initState = {
    isLoading: false,
    historyIsLoading: false,
    cursor: -1,
    queryUnread:true,
    endDatetime:0,
    timeCursor:0,
    dialogs: [],
    answersCommented: [],
    cacheDialogs: [],
    guideStep:-1
}

export default function reducer(state = initState, action) {
    switch (action.type) {
        case SET_GUIDE_STEP:
            return {
                ...state,
                guideStep:action.step
            }
        case START_SENDING_MESSAGE:
            return {
                ...state,
                isLoading: true,
                dialogs: [...state.dialogs.filter(d=>!(d.from ==='system'&& d.message === 'viewFlag')),
                    {
                        from: 'me',
                        message: action.message
                    },{
                        from: 'system',
                        message: 'viewFlag'
                    }
                ]
            }
        case SEND_WELCOME:
            return {
                ...state,
                dialogs: [...state.dialogs.filter(d=>!(d.from ==='system'&&d.message === 'viewFlag')),
                    {
                        from: 'system',
                        message: 'date'
                    },
                    {
                        from: 'other',
                        message: {
                            messageType:'welcome',
                            componentCode: "0",
                            answerObject: action.text
                        }
                    },{
                        from: 'system',
                        message: 'viewFlag'
                    }
                ]
            }
        case FINISH_SENDING_MESSAGE:
            return {
                ...state,
                isLoading: false,
                dialogs: [...state.dialogs.filter(d=>!(d.from ==='system'&&d.message === 'viewFlag')),
                    {
                        from: 'other',
                        message: action.message
                    },{
                        from: 'system',
                        message: 'viewFlag'
                    }
                ]
            }
        case START_LOADING_HISTORY:
            return {
                ...state,
                dialogs: [
                    {
                        from: 'system',
                        message: 'viewFlag'
                    },
                    ...state.dialogs.filter(d=>!(d.from ==='system'&&d.message ==='viewFlag'))
                ],
                historyIsLoading: true
            }
        case FINISH_LOADING_HISTORY:
            return {
                ...state,
                historyIsLoading: false,
                dialogs: [
                    ...action.history,
                    {
                        from: 'system',
                        message: 'viewFlag'
                    },
                    ...state.dialogs.filter(d=>!(d.from ==='system'&&d.message ==='viewFlag'))
                ],
                endDatetime: action.endDatetime,
                cursor: action.cursor,
                timeCursor: action.timeCursor
            }
        case MARK_ANSWER:
            return {
                ...state,
                answersCommented:[
                    ...state.answersCommented , 
                    action.content.supplementQuestionGroupId
                ]
            }  
        case  SET_CACHEDIALOGS:
            return {
                ...state,
                cacheDialogs:[
                    ...action.cacheDialogs
                ]
            }
        default:
            return state
    }
}