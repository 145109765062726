import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from './router/router';
import configStore from './redux/store';

import './setupApp';

const store = configStore();

ReactDOM.render(<Provider store={store}>{Router()}</Provider>, document.getElementById('root'));
