import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import DialogPanel from '../dialogPanel/dialogPanel';
import QueueAnim from 'rc-queue-anim';
import FaqItem from './faqItem';
import { ReactComponent as RefreshSvg } from '../../images/icon/refresh.svg';
import { ReactComponent as EmoSvg } from '../../images/icon/emo.svg';
import styles from './index.module.less';

class FaqPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      currentList: [this.props.faqs[0], this.props.faqs[1], this.props.faqs[2]],
      show: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.changeNext = this.changeNext.bind(this);
  }

  componentDidUpdate(prevProps,prevState){
    if(prevProps.faqs !== this.props.faqs)
      this.changeNext()
  }

  handleChange() {
    this.setState({
      currentList: [],
    });
    setTimeout(this.changeNext, 500);
  }

  changeNext() {
    let arr = [...this.props.faqs];
    let out = [];
    let num = 3;
    while (out.length < num) {
      let temp = (Math.random() * arr.length) >> 0;
      out.push(arr.splice(temp, 1)[0]);
    }
    this.setState({
      currentList: out,
    });
  }

  render() {
    const { isLoading } = this.props;
    if (this.props.faqs?.length === 0 ) {
      return <></>;
    } else {
      return (
        <DialogPanel>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: 10,
            }}
          >
            <div className={styles.panelTitle}>
              猜你想问&nbsp;
            <EmoSvg/>
            </div>
            <div onClick={this.handleChange} className={styles.change}>
              <RefreshSvg />
              <span>&nbsp;换一批</span>
            </div>
          </div>
          {/* <div  className="panelTitle">或许您想了解以下内容</div> */}
          <QueueAnim duration={450} appear={false} type={['left']}>
            {this.state.currentList.map((q, i) => {
              return <FaqItem content={q} key={i} />;
            })}
          </QueueAnim>
        </DialogPanel>
      );
    }
  }
}

FaqPanel.propTypes = {
  isLoading: propTypes.bool,
  faqs: propTypes.array,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.Faq.isLoading,
    faqs: state.Faq.faqs,
  };
};

export default connect(mapStateToProps, null)(FaqPanel);
