import { Component } from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { debounce } from 'lodash-es';
import Message from 'antd/lib/message';

import { sendMessage } from '../../redux/actions/home';
import styles from './index.module.less';
import utils from '../../utils/utils'

function getSuggestionValue(suggestion) {
  return suggestion.question;
}

function renderInputComponent(inputProps) {
  return <input {...inputProps} />;
}

class SendBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      selectedSuggestion: {},
      acIsLoading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.handleSendMessageInInputBox = this.handleSendMessageInInputBox.bind(this);
    this.handleInputEnter = this.handleInputEnter.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.debouncedLoadSuggestions = debounce(this.loadSuggestions, 500);
  }
  renderSuggestion =(suggestion)=> {
    const newSuggestion = suggestion.question.indexOf(this.state.value) > -1 ? suggestion.question.split(this.state.value).join(`<b><a style='color:#4285ff'>${this.state.value}</a></b>`) : suggestion.question
    return <div dangerouslySetInnerHTML={{__html: newSuggestion}}/>;
  }
  
  loadSuggestions(value) {
    // let testdata = [
    //   {
    //     question:'办理在校证明需要什么材料？'
    //   },
    //   {
    //     question:'可以在宿舍使用大功率电器吗？'
    //   }
    // ]
    // this.setState({  ...this.state,suggestions: testdata })
    this.setState({
      ...this.state,
      acIsLoading: true,
    });
    fetch(`${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/associationQuestions?input=${value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'AUTHORIZATION':utils.getCookie('token')
      },
    })
      .then(response => {
        if (response.status === 200) {
            return response.json()
        } else if(response.status === 401){
            utils.removeStorage('userinfo')
            window.location.href = ('/')
        }
      })
      .then((res) => {
        if (res) {
          if (res.data && res.data?.length > 0) {
            this.setState({ ...this.state, suggestions: res.data.map(item => {return {'question':item}})});
          }
        } else {
          window.console.log('Oops, error', res);
        }
      })
      .catch((e) => {
        window.console.log('Oops, error', e);
      });
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  }

  onSuggestionsFetchRequested({ value }) {
    this.debouncedLoadSuggestions(value);
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  onSuggestionSelected(e, s) {
    this.props.sendMessage({ render: s.suggestion.question, send: s.suggestion.question })
    this.setState({ value: '', selectedSuggestion: s.suggestion });
  }

  handleSendMessageInInputBox() {
    if (this.state.value.trim() == '') {
      Message.open({
        content: <p>亲！别太着急，先说点什么～</p>,
        icon: null,
      });
    } else {
      this.props.sendMessage({ render: this.state.value, send: this.state.value });
      this.setState({
        value: '',
        suggestions: [],
      });
    }
  }

  handleInputEnter(event) {
    if (event.key == 'Enter') {
      if (this.state.value == this.state.selectedSuggestion.question) {
        this.props.sendMessage({
          render: this.state.selectedSuggestion.question,
          send: this.state.selectedSuggestion.stdQuestion,
        });
        this.setState({
          value: '',
          suggestions: [],
          selectedSuggestion: {},
        });
      } else {
        this.handleSendMessageInInputBox();
      }
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: '请输入您的问题...',
      value,
      onChange: this.onChange,
      onKeyPress: this.handleInputEnter,
    };
    return (
      <div className={styles.sendBox}>
        <div className={styles.formDiv}>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            alwaysRenderSuggestion={true}
            inputProps={inputProps}
            renderInputComponent={renderInputComponent}
            onSuggestionSelected={this.onSuggestionSelected}
          />
          <div style={{ backgroundColor: '#fff', padding: 6.5, borderRadius: '0px 10px 10px 0px' }}>
            <button
              className={styles.sendBtn}
              onClick={this.handleSendMessageInInputBox}
              style={
                value
                  ? { background: '#4371ff', width: 60, borderRadius: 6 }
                  : { width: 33, borderRadius: 12 }
              }
            >
              <svg
                style={{ fill: 'currentColor', color: 'white' }}
                width="20"
                height="20"
                viewBox="0 0 16 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <title>icon/发送</title>
                <g id="icon/发送" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect id="矩形" x="0" y="0" width="16" height="16"></rect>
                  <path
                    d="M13.4977171,1.00226533 C13.9231675,0.978242454 14.3369698,1.14625452 14.6252307,1.46005857 C14.9134917,1.77386261 15.045828,2.20038299 14.9858159,2.62222596 L14.9938164,2.54322788 L14.9958165,2.64522541 L14.9968166,2.90721904 C14.9828157,4.76317394 14.6607943,7.06711796 13.8327393,9.54205783 C13.767735,9.73405316 13.7027307,9.9220486 13.6367263,10.1040442 C13.0866898,11.6180074 12.4906502,12.7539798 11.8836099,13.5649601 C11.5815899,13.9689503 11.3175723,14.2419436 11.1105586,14.4099395 L11.0805566,14.431939 L11.0225528,14.5079372 C10.7403534,14.8375987 10.3218115,15.0186119 9.88830527,14.9984825 C9.45479903,14.9783531 9.05483564,14.7593334 8.8044055,14.4049397 L8.77540357,14.3589408 L8.60939255,14.1569457 C7.71624869,13.0244372 7.12263289,11.6853119 6.88327795,10.2630403 L6.91628014,10.233041 L11.7576016,5.10716559 L11.8216058,5.02916748 C12.0207926,4.73793163 11.9632967,4.34262064 11.6893984,4.12018004 C11.4155001,3.89773944 11.0167644,3.9225318 10.7725362,4.17718818 L6.05622305,9.17206682 C4.49611947,8.91707301 3.06602453,8.31508764 1.88294599,7.38211031 L1.67993251,7.21511437 L1.62692899,7.18211517 C0.806874549,6.6171289 0.766871893,5.40315839 1.65193065,4.86217154 L1.67193198,4.85117181 L1.73293603,4.79517317 L1.90794765,4.64517681 L2.12996239,4.46818111 L2.25697082,4.36818354 C2.76900481,3.97819302 3.36004405,3.58820249 4.02708833,3.22421134 C4.8154212,2.79235324 5.64366174,2.43771224 6.50025253,2.16523707 C8.94041452,1.33925714 11.2325667,1.01826494 13.0936903,1.00426528 L13.2437002,1.00426528 L13.450714,1.0072652 L13.4977171,1.00226533 Z"
                    id="路径"
                    fill={value ? '#fff' : '#b1ceff'}
                  ></path>
                </g>
              </svg>

              {value && <span style={{fontSize:12}}>&nbsp;发送</span>}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SendBox.propTypes = {
  sendMessage: propTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (msg) => {
      dispatch(sendMessage(msg));
    },
  };
};

export default connect(null, mapDispatchToProps)(SendBox);
