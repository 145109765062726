import React, {Component} from 'react'
import propTypes from 'prop-types'
import {connect} from 'react-redux'
import {openDrawer} from "../../redux/actions/home"

class FirstStepTree extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.props.openDrawer({
            type: "tree",
            content: this.props.treeNodes
        })
    }

    render() {
        if(typeof this.props.treeNodes.answerObject == 'string') {
            return (<p>请选择您的情况</p>)
        } else {
            return (<p style={{color: "#3C6BC0"}} onClick={this.handleClick}>请选择您的情况</p>)
        }
    }
}

FirstStepTree.propTypes = {
    treeNodes: propTypes.object,
    openDrawer: propTypes.func
}

// const mapStateToProps = (state) => {
//     return {
//     }
// }

const mapDispatchToProps = (dispatch) => {
    return {
        openDrawer: (param) => {
            dispatch(openDrawer(param))
        }
    }
}

export default connect( null, mapDispatchToProps)(FirstStepTree)