import React, { Component } from 'react';
import propTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { Icon } from 'antd';

import { sendMessage } from '../../redux/actions/home';
import { ReactComponent as AskSvg } from '../../images/icon/ask.svg';
import styles from '../common.module.less';

class Navgation extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.props.sendMessage({ render: this.props.navigation, send: this.props.navigation });
  }
  render() {
    let navigation = this.props.navigation;
    return (
      <div onClick={this.handleClick} className={styles.navigation}>
        <div style={{ display: 'flex', color: '#000', alignItems: 'center', cursor: 'pointer' }}>
          <AskSvg/>
          &nbsp;
          <span style={{flex:1}}>{navigation}</span>
        </div>
        <Icon type="right" style={{ color: 'rgb(200, 200, 200)', fontSize: 12 }} />
      </div>
    );
  }
}

Navgation.propTypes = {
  navigation: propTypes.string,
  sendMessage: propTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (msg) => {
      dispatch(sendMessage(msg));
    },
  };
};

export default connect(null, mapDispatchToProps)(Navgation);
