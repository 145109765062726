import 'isomorphic-fetch'
import getUuid from '../../utils/getUuid'
import utils from '../../utils/utils'
import {message} from 'antd';
import localVideoSrc from '../../images/default.mp4';
export const START_SENDING_MESSAGE = 'START_SENDING_MESSAGE'
export const FINISH_SENDING_MESSAGE = 'FINISH_SENDING_MESSAGE'
export const START_LOADING_QUICKASK = 'START_LOADING_QUICKASK'
export const FINISH_LOADING_QUICKASK = 'FINISH_LOADING_QUICKASK'
export const START_LOADING_HISTORY = 'START_LOADING_HISTORY'
export const FINISH_LOADING_HISTORY = 'FINISH_LOADING_HISTORY'
export const START_LOADING_FAQ = 'START_LOADING_FAQ'
export const FINISH_LOADING_FAQ = 'FINISH_LOADING_FAQ'
export const OPEN_DRAWER_PANEL = 'OPEN_DRAWER_PANEL'
export const CLOSE_DRAWER_PANEL = 'CLOSE_DRAWER_PANEL'
export const SEND_WELCOME = 'SEND_WELCOME'
export const MARK_ANSWER = 'MARK_ANSWER'
export const PLAY_VIDEO = 'PLAY_VIDEO'
export const INIT_NOTICES = 'INIT_NOTICES'
export const CHANGE_MODE = 'CHANGE_MODE'
export const VIDEO_LISTENER = 'VIDEO_LISTENER'
export const SET_CACHEDIALOGS = 'SET_CACHEDIALOGS'
export const PLAYED_VIDEO = 'PLAYED_VIDEO'
export const SET_GUIDE_STEP = 'SET_GUIDE_STEP'
// const welcomeText = {
//     'am':[
//         '早上好，有问题欢迎向我咨询。',
//         '早上好，祝你整天都有好心情～'
//     ],
//     'pm':[
//         '下午好，有问题欢迎向我咨询。',
//         '下午好，不要忘记吃饭哦～'
//     ],
//     'evening':[
//         '晚上好，有问题欢迎向我咨询。',
//         '晚上好，今天快要结束了，好好休息吧。'
//     ],
//     'night':[
//         '晚上好，有问题欢迎向我咨询。',
//         '晚上好，夜深了，祝你做个好梦。'
//     ]
// }
let text = ''

export function startSendingMessage(msg) {
    return{
        type: START_SENDING_MESSAGE,
        message: msg
    }
}

export function finishSendingMessage(answer) {
    return{
        type: FINISH_SENDING_MESSAGE,
        message:answer
    }
}

export function startLoadingQuickAsk() {
    return{
        type: START_LOADING_QUICKASK
    }
}

export function finishLoadingQuickAsk(res) {
    return{
        type: FINISH_LOADING_QUICKASK,
        quickAsks: res.data
    }
}

export function startLoadingFaq() {
    return{
        type: START_LOADING_FAQ
    }
}

export function finishLoadingFaq(res) {
    return{
        type: FINISH_LOADING_FAQ,
        faqs:res.data
    }
}

export function startLoadingHistory() {
    return{
        type: START_LOADING_HISTORY
    }
}

export function finishLoadingHistory(res,unread,cache) {
    return {
        type: FINISH_LOADING_HISTORY,
        history: convertMessageArr(res.results,unread,cache),
        cursor: res.results.length <20 && !unread ? 1 : Number(new Date()),
        timeCursor: res.timeCursor
    }
}

function convertMessageArr(messageArr,unread,cache) {
    let hisroty = []
    let messageArr2 = unread ? messageArr.filter(i=>i.pairType !== 0) : messageArr
    for(let m of messageArr2) {
        if(m.question&&m.pairType === 0) {
            hisroty.push({
                from: 'me',
                message: m.question
            })
        }
        if(m.answer) {
            let anchorIndex = messageArr2.filter(item=>item.pairType !== 0).findIndex(i=>i===m) 
            let scrollTo = unread && anchorIndex===0
            switch (m.pairType) {
                case 0 : 
                    let answer = JSON.parse(m.answer)
                    if(answer[1]) {
                        if(answer[1].componentCode===8) {
                            answer[0].mark = answer[1]
                        }
                    }
                    answer[0].answerObject.split('\n下为新消息:\n---').map((i,index,arr)=>{
                        let obj = {"messageType":"answer","question":m.question,"navigation":index === arr.length-1 ? answer[0].navigation : [],"detail":index === arr.length-1 ? answer[0].detail : '',"chatId":`${m.id}-${index}`,"componentCode":"1","answerObject":i}
                        obj.videoProps={"chatId":m.id,"videoType":"qa","videoUrl":null}
                        hisroty.push({
                            from:'other',
                            message:obj
                    })})
                    break
                case 1 : 
                    if(scrollTo){
                        hisroty.push(
                            {
                                from:'system',
                                message:{'from':'feedback'}
                            },
                        )
                    }
                    m.answer.split('\n下为新消息:\n---').map((i,index)=>hisroty.push({
                            from:'other',
                            message:{"messageType":"feedback","navigation":[],"text":m.question?.includes('text') ? JSON.parse(m.question).text : '',"time":m.question?.includes('createTime') ? JSON.parse(m.question).createTime : m.question,"chatId":`${m.id}-${index}`,"componentCode":"1","answerObject":i}
                    }))  
                    break     
                case 2 :
                    if(scrollTo){
                        hisroty.push({
                            from:'system',
                            message:{'from':'notice'}
                        })
                    }
                    m.answer.split('\n下为新消息:\n---').map((i,index)=>hisroty.push({
                            from:'other',
                            message:{"messageType":"notice","navigation":[],"question":m.question,"chatId":`${m.id}-${index}`,"componentCode":"1","answerObject":i,"videoProps":{"chatId":m.id,"videoType":unread ? 'initNotice' : "notice","videoUrl":m.videoUrl}}
                    }))  
                    break                    
                   
            }

        }
    }
    if(cache?.length>0){
        for(let cacheItem of cache) {
            if(cacheItem.question&&cacheItem.pairType === 0) {
                hisroty.push({
                    from: 'me',
                    message: cacheItem.question
                })
            }
            if(cacheItem.answer) {
                let answer = JSON.parse(cacheItem.answer)
                answer[0].answerObject.split('\n下为新消息:\n---').map((i,index,arr)=>{
                    let obj = {"messageType":"answer","navigation":index === arr.length-1 ? answer[0].navigation : [],"detail":index === arr.length-1 ? answer[0].detail : '',"componentCode":"1","answerObject":i,"chatId":`${cacheItem.id}-${index}`}
                    obj.videoProps={"chatId":cacheItem.id,"videoType":"qa","videoUrl":null}
                    hisroty.push({
                        from:'other',
                        message:obj
                })})
            }
        }
    }
    return hisroty
}

export function loadingFaq() {
    return dispatch => {
        if(!utils.getCookie('token')){
            window.location.href=('/');
        }else{
        dispatch(startLoadingFaq())
        return fetch(`${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/hot-question/current`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'AUTHORIZATION':utils.getCookie('token')
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if(response.status === 401 || response.status === 403){
                    utils.removeStorage('userinfo')
                    window.location.href = ('/')
                }
            })
            .then(res => {
                if (res) {
                    dispatch(finishLoadingFaq(res))
                } else {
                    window.console.log("Oops, error", res)
                }
            })
            .catch(e => {
                window.console.log("Oops, error", e)
            })
        }
    }
}

export function loadingQuickAsk() {
    return dispatch => {
        if(!utils.getCookie('token')){
            window.location.href=('/');
        }else{
        dispatch(startLoadingQuickAsk())
        return fetch(`${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/quick-ask/current`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'AUTHORIZATION':utils.getCookie('token')
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if(response.status === 401 || response.status === 403){
                    utils.removeStorage('userinfo')
                    window.location.href = ('/')
                }
            })
            .then(res => {
                if (res) {
                    dispatch(finishLoadingQuickAsk(res))
                } else {
                    window.console.log("Oops, error", res)
                }
            })
            .catch(e => {
                window.console.log("Oops, error", e)
            })
        }
    }
}

export function getHistory(params) {
    return dispatch => {
        if(!utils.getCookie('token')){
            window.location.href=('/');
        }else{
        dispatch(startLoadingHistory())
        if(!params.queryUnread){
            dispatch(setNoticeVideos())
        }
        return fetch(`${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/chatmessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'AUTHORIZATION':utils.getCookie('token')
            },
            body:JSON.stringify({queryUnread:params.queryUnread,endDatetime:params.endDatetime})
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if(response.status === 401 || response.status === 403){
                    utils.removeStorage('userinfo')
                    window.location.href = ('/')
                }
            })
            .then(resp => {
                if(resp.resultCode === 1){
                    message.error(resp.resultMessage)
                }
                else{
                    if (resp) {
                        if(!params.queryUnread){
                            dispatch(setNoticeVideos())
                        }
                        let res = resp
                        // if(params.queryUnread){
                            // res.results = [    
                            //         {
                            //             "id": 121557,
                            //             "userId": "2",
                            //             "question": "2022-09-28 10:58:20",
                            //             "answer": "测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n",
                            //             "createTime": 1664333905078,
                            //             "createTime2": "2022-09-28 10:58:20",
                            //             "pairType": 1,
                            //             "knowledgeId": null,
                            //             "bizId": 64,
                            //             "videoUrl": null,
                            //             "read": true
                            //         },
                            //         // {
                                        
                            //         //         "id": 120828,
                            //         //         "userId": "2",
                            //         //         "question": "2022-10-11 10:50:06",
                            //         //         "answer": "我的background\n![](https://stg.xeducationfile.leyantech.com/college/feedback/pic/b73417background.png)\n",
                            //         //         "createTime": 1665493604598,
                            //         //         "createTime2": "2022-10-11 21:06:45",
                            //         //         "pairType": 2,
                            //         //         "knowledgeId": null,
                            //         //         "bizId": 168,
                            //         //         "videoUrl": "https://stg.xeducationfile.leyantech.com/college/video/sth/1cfb4f_269.mp4",
                            //         //         "read": true
                                        
                            //         // },
                            //         // {
                            //         //     "id": 120970,
                            //         //     "userId": "2",
                            //         //     "question": "2022-10-12 21:01:33",
                            //         //     "answer": "测试内容1\n测试内容1\n测试内容1\n测试内容1\n测试内容1\n测试内容1\n测试内容1\n测试内容1",
                            //         //     "createTime": 1665579730687,
                            //         //     "createTime2": "2022-10-12 21:02:11",
                            //         //     "pairType": 2,
                            //         //     "knowledgeId": null,
                            //         //     "bizId": 231,
                            //         //     "videoUrl": "https://stg.xeducationfile.leyantech.com/college/video/sth/e4bdb9_320.mp4",
                            //         //     "read": true
                            //         // },
                            //         // {
                            //         //         "id": 120972,
                            //         //         "userId": "2",
                            //         //         "question": "2022-10-12 21:01:36",
                            //         //         "answer": "测试内容2\n测试内容2\n测试内容2\n测试内容2\n测试内容2\n测试内容2\n测试内容2",
                            //         //         "createTime": 1665579759985,
                            //         //         "createTime2": "2022-10-12 21:02:40",
                            //         //         "pairType": 2,
                            //         //         "knowledgeId": null,
                            //         //         "bizId": 232,
                            //         //         "videoUrl": "https://stg.xeducationfile.leyantech.com/college/video/sth/745507_321.mp4",
                            //         //         "read": true
                            //         // },
                            //         // {
                            //         //         "id": 120974,
                            //         //         "userId": "2",
                            //         //         "question": "2022-10-12 21:02:03",
                            //         //         "answer": "测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3",
                            //         //         "createTime": 1665579780749,
                            //         //         "createTime2": "2022-10-12 21:03:01",
                            //         //         "pairType": 2,
                            //         //         "knowledgeId": null,
                            //         //         "bizId": 233,
                            //         //         "videoUrl": "https://stg.xeducationfile.leyantech.com/college/video/sth/26aa77_322.mp4",
                            //         //         "read": true
                            //         // },
                            //         {
                            //             "id": 120976,
                            //             "userId": "2",
                            //             "question": "测试通知",
                            //             "answer": "测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n\n下为新消息:\n---\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3\n测试内容3",
                            //             "createTime": 1665579802484,
                            //             "createTime2": "2022-10-12 21:03:22",
                            //             "pairType": 2,
                            //             "knowledgeId": null,
                            //             "bizId": 234,
                            //             "videoUrl": "https://stg.xeducationfile.leyantech.com/college/video/sth/26aa77_322.mp4",
                            //             "read": true
                            //         },
                            //         {
                            //             "id": 120979,
                            //             "userId": "2",
                            //             "question": "测试通知",
                            //             "answer": "测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n\n下为新消息:\n---\n测试内容4\n测试内容4\n测试内容4\n测试内容4",
                            //             "createTime": 1665579802484,
                            //             "createTime2": "2022-10-12 21:03:22",
                            //             "pairType": 2,
                            //             "knowledgeId": null,
                            //             "bizId": 234,
                            //             "videoUrl": "https://stg.xeducationfile.leyantech.com/college/video/sth/260f9f_323.mp4",
                            //             "read": true
                            //         },
                            //         {
                            //             "id": 120977,
                            //             "userId": "2",
                            //             "question": "测试通知",
                            //             "answer": "测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n",
                            //             "createTime": 1665579802484,
                            //             "createTime2": "2022-10-12 21:03:22",
                            //             "pairType": 2,
                            //             "knowledgeId": null,
                            //             "bizId": 234,
                            //             "videoUrl": null,
                            //             "read": true
                            //         }
                            //         // {
                            //         //     "id": 21372,
                            //         //     "userId": "2",
                            //         //     "question": "2022-10-31 18:50:24",
                            //         //     "answer": "pdf：\n\nhttps://stg.xeducationfile.leyantech.com/college/uploadFile/sth/a71fcad_.pdf\n\n下为新消息:\n---\nppt：\n\n下为新消息:\n---\n\nhttps://stg.xeducationfile.leyantech.com/college/uploadFile/sth/7a97876_ok.pptx\n\n下为新消息:\n---\nexcel：\n\nhttps://stg.xeducationfile.leyantech.com/college/uploadFile/sth/7538958_.xlsx\n\n下为新消息:\n---\nmp4：\n\nhttps://stg.xeducationfile.leyantech.com/college/uploadFile/sth/c08f3d7_GoogleChrome20220314180156.mp4\n",
                            //         //     "createTime": 1667213473852,
                            //         //     "createTime2": "2022-10-31 18:51:14",
                            //         //     "pairType": 2,
                            //         //     "knowledgeId": null,
                            //         //     "bizId": 286,
                            //         //     "videoUrl": "https://stg.xeducationfile.leyantech.com/college/video/sth/131565_369.mp4",
                            //         //     "read": true
                            //         //   },
    
                            // ]
                        // }
                        if(params.queryUnread === true ){
                            let arr = res?.results?.filter(item=>item.pairType === 2&&item.videoUrl) ?? []
                            if(arr.length>0){
                                dispatch(changeMode('video'))
                            }else if(!utils.getStorage('mode') || utils.getStorage('mode')==='video'){
                                dispatch(playContentVideo(text,{}))
                            }
                        }
                        if(params.queryUnread === true){
                            dispatch({
                                type:SET_CACHEDIALOGS,
                                cacheDialogs:res?.results?.filter(item=>item.pairType === 0) ?? []
                            })
                        }
                        if(params.cache?.length){
                            dispatch({
                                type:SET_CACHEDIALOGS,
                                cacheDialogs:[]
                            })
                        }
                        dispatch(finishLoadingHistory(res,params.queryUnread,params.cache))
                    } else {
                        window.console.log("Oops, error", resp)
                    }

                }
            })
            .catch(e => {
                console.log(e.status)
                if(e.status === 401){
                    utils.removeStorage('userinfo')
                    window.location.href = ('/')
                }
                window.console.log("Oops, error", e)
            })
        }
    }
}

export function sendWelcome() {
    let arr = []
    let welcomeText = {}
    let videoStandbyUrl = ''
    try{
        welcomeText = JSON.parse(JSON.parse(utils.getStorage('org-config')).welcomeText)
        videoStandbyUrl = JSON.parse(utils.getStorage('org-config')).videoStandbyUrl
    }catch(e){
        utils.removeStorage('org-config')
        window.location.href = ('/')
        console.log('error',e)
    }
    let hour = new Date().getHours()
    if(hour >=0 && hour < 6){
        arr = welcomeText.night
    }
    else if(hour >=6 && hour < 12){
        arr = welcomeText.am
    }
    else if(hour >=12 && hour < 18){
        arr = welcomeText.pm
    }
    else if(hour >=18 && hour < 24){
        arr = welcomeText.evening
    }
    text = arr[Math.floor(Math.random() * arr.length)]

    return{
        type: SEND_WELCOME,
        text:text
    }
}

export function onGetCurrentOrgConfig(){
    let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/org-config/current`
    fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'AUTHORIZATION':utils.getCookie('token')
      },
    })
    .then(response => {
        if (response.status === 200) {
            return response.json()
        } else if(response.status === 401 || response.status === 403){
            utils.removeStorage('userinfo')
            window.location.href = ('/')
        }
    })
    .then(res => {
      if (res.resultCode === 0) {
        utils.setStorage('org-config',JSON.stringify({...res.data}))
        if(JSON.stringify(res.data) !== utils.getStorage('org-config')){
            utils.removeStorage('org-config')
            window.location.href = ('/')
        }
      }else if(res.displayMessage){
        message.error(res.displayMessage)
      }
    })
    .catch(e => {
        window.console.log("Oops, error", e)
    })
  }


export function playContentVideo(content,params){
    return dispatch => {
        if(!utils.getCookie('token')){
            window.location.href=('/');
        }else{
        return fetch(`${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/getVideoUrlByContent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'AUTHORIZATION':utils.getCookie('token')
            },
            body:JSON.stringify({content:content})
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if(response.status === 401 || response.status === 403){
                    utils.removeStorage('userinfo')
                    window.location.href = ('/')
                }
            })
            .then(res => {
                if (!res) {
                    window.console.log("Oops, error", res)
                }else{
                    let obj = {...params}
                    if(obj){
                        obj.videoUrl = res.data
                        obj.play = params.play
                        obj.videoType = 'match'
                        obj.init = true
                    }
                    dispatch({
                        type:PLAY_VIDEO,
                        video:obj
                    })
                }
            })
            .catch(e => {
                window.console.log("Oops, error", e)
            })
        }
    }

}

export function sendMessage(msg) {
    return dispatch => {
        if(!utils.getCookie('token')){
            window.location.href=('/');
        }else{
            dispatch(startSendingMessage(msg.render))
            // console.log(process.env)
            return fetch(`${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/qa`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'AUTHORIZATION':utils.getCookie('token')
                },
                body:JSON.stringify({userId: getUuid(),question:msg.send})
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json()
                } else if(response.status === 401 || response.status === 403){
                        utils.removeStorage('userinfo')
                        window.location.href = ('/')
                    }
                })
                .then(res => {
                    if (res.answer) {
                        let answer = JSON.parse(res.answer)
                        // 赞和踩
                        if(answer[1]) {
                            if(answer[1].componentCode==8) {
                                answer[0].mark = answer[1]
                                answer[0].mark.supplementQuestionGroupId = res.supplementQuestionGroupId
                                answer[0].mark.userContextId = res.userContextId
                            }
                        }
                        answer[0].answerObject.split('\n下为新消息:\n---').map((i,index,arr)=>{
                            let obj = {...answer[0]}
                            obj.question = msg.send
                            obj.messageType = 'answer'
                            // obj.detail = 'lalala'
                            obj.detail = index === arr.length-1 ? answer[0].detail : ''
                            obj.chatId = `${answer[0]?.mark?.supplementQuestionGroupId}-${index}`
                            obj.answerObject = i
                            // obj.answerObject = '测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n测试内容4\n'
                            obj.trigerGuide = res.trigerGuide && index === arr.length - 1 ? true : false,
                            // obj.trigerGuide = true
                            obj.videoProps = {"chatId":answer[0]?.mark?.supplementQuestionGroupId,"videoType":"qa","videoUrl":null}
                            if(!utils.getStorage('mode') || utils.getStorage('mode')==='video'){
                                dispatch(playVideo({"chatId":answer[0]?.mark?.supplementQuestionGroupId,"videoType":"qa","videoUrl":null},true))
                            }
                            dispatch(finishSendingMessage(obj))
                        })
                        // 触发打开drawer
                        if (answer[0].componentCode === "9") {
                            let content = answer[0]
                            content.supplementQuestionGroupId = res.supplementQuestionGroupId
                            dispatch(openDrawer({
                                type: "tree",
                                content: content
                            }))
                        }
                    } else {
                        window.console.log("Oops, error", res)
                    }
                })
                .catch(e => {
                    window.console.log("Oops, error", e)
                })
        }
    }
}

export function mark(msg) {
    return dispatch => {
        if(!utils.getCookie('token')){
            window.location.href=('/');
        }else{
        dispatch({
            type:MARK_ANSWER,
            content:msg
        })
        return fetch(`${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/comment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'AUTHORIZATION':utils.getCookie('token')
            },
            body:JSON.stringify({chatId:msg.supplementQuestionGroupId,content:msg.gradeComment,tags:msg.tags})
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if(response.status === 401 || response.status === 403){
                    utils.removeStorage('userinfo')
                    window.location.href = ('/')
                }
            })
            .then(res => {
                if (!res) {
                    window.console.log("Oops, error", res)
                }
            })
            .catch(e => {
                window.console.log("Oops, error", e)
            })
        }
    }
}

export function openDrawer(param) {
    if(param.type === "intro") {
        return dispatch => {
            dispatch({
                type: OPEN_DRAWER_PANEL,
                content:param
            })
        }
    } else if(param.type === "tree") {
        return dispatch => {
            dispatch({
                type: OPEN_DRAWER_PANEL,
                content: param
            })
        }
    }
}

export function closeDrawer() {
    return dispatch => {
        dispatch({
            type: CLOSE_DRAWER_PANEL
        })
    }
}

export function playVideo(params,play){
    switch (params.videoType) {
        case 'qa' :
            return dispatch => {
                if(!utils.getCookie('token')){
                    window.location.href=('/');
                }else{
                let url = `${process.env.REACT_APP_DOMAIN_NAME}/xbizapi/3715/getVideoUrlByChatId`
                let paramsArray = [];
                Object.keys({chatId:params.chatId}).forEach(key => paramsArray.push(key + '=' + {chatId:params.chatId}[key]))
                if (url.search(/\?/) === -1) {
                    url += '?' + paramsArray.join('&')
                } else {
                    url += '&' + paramsArray.join('&')
                }
                return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'AUTHORIZATION':utils.getCookie('token')
                    },
                })
                    .then(response => {
                        if (response.status === 200) {
                            return response.json()
                } else if(response.status === 401 || response.status === 403){
                            utils.removeStorage('userinfo')
                            window.location.href = ('/')
                        }
                    })
                    .then(res => {
                        if (!res) {
                            window.console.log("Oops, error", res)
                        }else{
                            params.videoUrl = res.data
                            if(play){
                                params.play = true
                            }
                            dispatch({
                                type:PLAY_VIDEO,
                                video:params
                            })
                            dispatch(setNoticeVideos())
                        }
                    })
                    .catch(e => {
                        window.console.log("Oops, error", e)
                    })
                }
            }
        case 'notice' : case 'match' :
            return dispatch => {
                dispatch({
                    type: PLAY_VIDEO,
                    video:params
                })
            }
        case 'initNotice' : 
            return dispatch => {
                dispatch({
                    type: PLAY_VIDEO,
                    video:params
                }),
                dispatch({
                    type:PLAYED_VIDEO,
                    playedVideo:params
                })
            }
        case 'default' :
            return dispatch => {
                dispatch({
                    type: PLAY_VIDEO,
                    video:{'videoType':'default','videoUrl':JSON.parse(utils.getStorage('org-config')).videoStandbyUrl ?? localVideoSrc}
                })
            }
    }
}

export function setNoticeVideos(params){
    return dispatch => {
        dispatch({
            type:INIT_NOTICES,
            video:params
            // videoList:params
        })
    }
}

export function changeMode(params){
    return dispatch => {
        dispatch({
            type:CHANGE_MODE,
            mode:params
        })
        dispatch(setNoticeVideos())
    }
}
export function setGuideStep(params){
    return dispatch => {
        dispatch({
            type:SET_GUIDE_STEP,
            step:params
        })
    }
}

