import React, { Component } from 'react';
import { Result } from 'antd';

class Error extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <Result
            status="500"
            title="500"
            subTitle="抱歉，服务终止"
        />
    );
  }
}

export default Error;
