import {OPEN_DRAWER_PANEL, CLOSE_DRAWER_PANEL} from "../actions/home"
//initState.content.type : enum{"intro", "tree"}
const initState = {
    isLoading: false,
    open: false,
    content: {
        type: "intro"
    }
}

export default function reducer(state = initState, action) {
    switch (action.type) {
        case OPEN_DRAWER_PANEL:
            return {
                ...state,
                open: true,
                content: action.content
            }
        case CLOSE_DRAWER_PANEL:
            return {
                ...state,
                open: false
            }
        default:
            return state
    }
}