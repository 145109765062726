import React, { Component } from 'react';
import propTypes from 'prop-types';

import styles from './index.module.less';

class DialogPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className={styles.dialogPanel}>{this.props.children}</div>;
  }
}

DialogPanel.propTypes = {
  navigator: propTypes.array,
  babelKey: propTypes.string,
  children: propTypes.node.isRequired,
};

export default DialogPanel;
