import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import { sendMessage } from '../redux/actions/home';
import styles from './common.module.less';

class QuickAskItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseDown: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    // this.handleTouchStart = this.handleTouchStart.bind(this)
    // this.handleTouchEnd = this.handleTouchEnd.bind(this)
  }

  handleClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    const { ask } = this.props;
    this.props.sendMessage({ render: ask, send: ask });
  }
  handleTouchEnd() {
    this.setState({ mouseDown: false });
    this.handleClick();
  }
  // handleTouchStart(e){
  //     e.preventDefault()
  //     console.log('hh',e.preventDefault())
  //     this.setState({mouseDown:true})
  // }
  // handleTouchEnd(){
  //     this.setState({mouseDown:false})
  //     this.handleClick()
  // }
  render() {
    return (
      <div
        className={styles.quickAskItem}
        style={{ backgroundColor: this.state.mouseDown ? 'rgb(216, 232, 255)' : '#fff' }}
        onClick={this.handleClick}
        onTouchStart={() => this.setState({ mouseDown: true })}
        onTouchEnd={() => this.setState({ mouseDown: false })}
        // onTouchStart={e=>this.handleTouchStart(e)}
        // onTouchEnd={this.handleTouchEnd}
        onMouseDown={() => this.setState({ mouseDown: true })}
        onMouseUp={() => this.setState({ mouseDown: false })}
      >
        {this.props.ask}
      </div>
    );
  }
}

QuickAskItem.propTypes = {
  ask: propTypes.string,
  sendMessage: propTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (msg) => {
      dispatch(sendMessage(msg));
    },
  };
};

export default connect(null, mapDispatchToProps)(QuickAskItem);
